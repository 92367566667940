import { Button, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";


import { useQuery, useQueryClient } from "react-query";
import axiosClient from "../api-client";

const Content = styled("div")``;

const Header = styled("div")`
  display: flex;
`;

const StyledForm = styled("form")`
  padding: 50px 10px 20px 10px;
`;

const StyledActionButtons = styled("div")`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Form = (props) => {
  const { data, handleSubmit } = props;
  const { t } = useTranslation();
	const navigate = useNavigate();
  

  const validate = (values) => {
    const errors = {};

    if (!values?.name) {
      errors.name = t("warnings.fieldRequired");
    } else if (values.name.length > 100) {
      errors.name = t("warnings.maxLength") + "100";
    }
    if (values?.description?.length > 1000) {
      errors.description = t("warnings.maxLength") + "1000";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
    },
    validate,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Header>
        <Typography variant={"h4"} component={"div"}>
          {data?.name||""}
        </Typography>
      </Header>

      <Content>
        <StyledForm onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            label={t("ChangeList.name") + "*"}
            id="name"
            onChange={formik.handleChange}
            value={formik.values?.name}
            error={!!formik.errors.name}
            helperText={formik.errors.name}
          />

          <br />
          <br />
          <TextField
            fullWidth
            label={t("ChangeList.description")}
            id="description"
            onChange={formik.handleChange}
            value={formik.values?.description}
            error={!!formik.errors.description}
            helperText={
              formik.errors.description ||
              formik.values.description.length + "/1000"
            }
          />
          <br />
          <br />

          <StyledActionButtons>
            <Button
              variant="outlined"
              color="black"
              onClick={() => navigate(-1)}
            >
              {t("ChangeList.cancelButton")}
            </Button>
            <Button variant="contained" type="submit">
              {t("ChangeList.submitButton")}
            </Button>
          </StyledActionButtons>
        </StyledForm>
      </Content>
    </div>
  );
};

const ChangeList = () => {
  const { listId } = useParams();
	const navigate = useNavigate();
  
	const queryClient = useQueryClient();
  
	const { isLoading, data } = useQuery(["gift-list", listId], () =>
    axiosClient
      .get(`/api/v1/gifts/gift-lists/${listId}/`)
      .then((res) => res.data)
  );

  const handleSubmit = (data) =>
    axiosClient
      .put(`/api/v1/gifts/gift-lists/${listId}/`, data)
      .then((res) => navigate(`/lists/${res.data.id}`))
      .finally(() => {
        queryClient.invalidateQueries("my-list");
      });

  if (isLoading) return "loading";

  return <Form data={data} handleSubmit={handleSubmit}/>;
};

export default ChangeList;
