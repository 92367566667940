import { Box, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import { useMyLists } from "../hooks";
import { useState } from "react";

export default function ActionButtons({ listId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { _delete } = useMyLists();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  return (
    <Box>
      <br />
      <Box minWidth="160px" textAlign="right">
        <Button
          variant="outlined"
          color="black"
          size="small"
          onClick={() => navigate(`/lists/${listId}/change/`)}
        >
          {t("GiftList.changeButton")}
        </Button>
        &nbsp;
        <Button
          variant="contained"
          size="small"
          onClick={() => setDeleteConfirmationOpen(true)}
        >
          {t("GiftList.deleteButton")}
        </Button>
      </Box>
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={() => _delete.mutate(listId)}
        title={t("GiftList.deleteConfirmation")}
        message={<Trans i18nKey={"GiftList.deleteConfirmationMessage"} />}
        confirmText={t("GiftList.deleteButton")}
      />
    </Box>
  );
}
