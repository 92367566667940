import {
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  PRE_CLOSE_LOGIN_MODAL,
  OPEN_GIFT_MODAL,
  PRE_CLOSE_GIFT_MODAL,
  CLOSE_GIFT_MODAL,
} from "../actions/modals";

const initialState = {
  login: {
    open: false,
  },
  gift: {
    open: false,
    data: null,
  },
};

export const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    // login
    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        login: {
          ...state.login,
          open: true,
        },
      };
    case PRE_CLOSE_LOGIN_MODAL:
      return {
        ...state,
        login: {
          ...state.login,
          open: false,
        },
      };
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        login: {
          ...state.login,
        },
      };

    // gift
    case OPEN_GIFT_MODAL:
      return {
        ...state,
        gift: {
          ...state.gift,
          open: true,
          data: action.data,
        },
      };
    case PRE_CLOSE_GIFT_MODAL:
      return {
        ...state,
        gift: {
          ...state.gift,
          open: false,
        },
      };
    case CLOSE_GIFT_MODAL:
      return {
        ...state,
        gift: {
          ...state.gift,
          data: null,
        },
      };

    default:
      return state;
  }
};
