import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import CssTextField from "../components/CssTextField";
import axiosClient from "../api-client";
import { useMobile } from "../components/MobileWrapper";

const PasswordRecoverPage = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");

  const validateEmail = (value) => {
    const errors = {};
    if (!value) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      errors.email = t("Common.invalidEmail");
    }
    setErrors(errors);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    validateEmail(value);
    setEmail(value);
  };

  const { mutate, isLoading } = useMutation("password-recovery", () => {
    const data = { email };
    axiosClient
      .post(`/api/v1/auth/recover-password/`, data)
      .then((response) => {
        toast(t("Recover.success"));
        setTimeout(() => navigate(`/recover-success`), 1000);
      })
      .catch((err) => setErrors(err.response.data));
  });

  const onKeyDown = (e) => (e.key === "Enter" ? mutate() : null);

  const mobileSx = isMobile ? {} : { width: "500px" };
  return (
    <Box sx={{ m: "auto", ...mobileSx }}>
      <Typography variant={"h4"} component={"div"}>
        {t("Recover.recoverAccount")}
      </Typography>

      <br />
      <Typography>{t("Recover.text1")}:</Typography>
      <ol>
        <li>
          <Typography>{t("Recover.text2")}</Typography>
        </li>
        <li>
          <Typography>{t("Recover.text3")}</Typography>
        </li>
        <li>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("Recover.text4")}
          </Typography>
        </li>
      </ol>

      <br />

      <CssTextField
        name="email"
        aria-label="register-email-input"
        focusColor="black"
        fullWidth
        placeholder={t("Register.email")}
        label={t("Register.email")}
        value={email}
        onChange={handleEmailChange}
        type="email"
        onKeyDown={onKeyDown}
        error={!!errors.email}
        helperText={errors.email}
      />
      <br />
      <br />
      <Button
        aria-label="login-button"
        fullWidth
        variant="contained"
        onClick={mutate}
        disabled={isLoading}
      >
        {t("Recover.recoverAccount")}
      </Button>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => navigate("/")}
        disabled={isLoading}
        sx={{ mt: "20px" }}
      >
        {t("Recover.back")}
      </Button>
    </Box>
  );
};

export default PasswordRecoverPage;
