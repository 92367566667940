import { connect } from "react-redux";

import { useMyGifts } from "../hooks";
import { openGiftModal } from "../store/actions/modals";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { FaShoppingBag } from "react-icons/fa";
import { PhotoView } from "react-photo-view";
import { FaCheckCircle, FaRegSmile } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import StyledFlex from "../components/StyledFlex";

const MyGiftsTable = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "MyGiftsTable" });
  const { t: _t } = useTranslation();
  const { listId } = useParams();
  const { data: myGifts, isLoading } = useMyGifts();
  const gifts = myGifts.filter(
    (gift) =>
      String(gift.list) === listId && !gift.is_pending_proposition_approval
  );

  if (isLoading) return null;
  if (!gifts.length) return <div>{t("noGifts")}</div>;

  return (
    <Box>
      {gifts.map((gift) => (
        <Card
          elevation={2}
          key={gift.id}
          sx={{ m: "20px -20px", border: "1px solid #e0e0e0" }}
        >
          <CardContent>
            <Typography sx={{ fontWeight: "bold" }} gutterBottom>
              {gift.name}
            </Typography>
          </CardContent>
          {!!gift.image_link && (
            <PhotoView src={gift.image_link}>
              <CardMedia
                sx={{ height: "280px" }}
                image={gift.image_link}
                title={gift.image_link}
              />
            </PhotoView>
          )}
          {!gift.image_link && (
            <StyledFlex
              bgcolor="#fafafa"
              height="280px"
              sx={{ justifyContent: "center", opacity: 0.5 }}
            >
              No image
            </StyledFlex>
          )}
          <CardContent>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {gift.description}
            </Typography>
          </CardContent>
          <CardContent>
            {gift.reserved ? (
              <Chip
                label={t("reserved")}
                avatar={<FaCheckCircle color="green" />}
              />
            ) : (
              <Chip label={t("available")} avatar={<FaRegSmile />} />
            )}
          </CardContent>

          <CardActions>
            {!!gift?.buy_link && (
              <Button
                fullWidth
                variant="contained"
                color="green"
                size="small"
                startIcon={<FaShoppingBag size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(gift.buy_link, "_blank");
                }}
              >
                {t("shop")}
              </Button>
            )}
            <Button
              fullWidth
              variant="outlined"
              onClick={() => props.openGiftModal(gift)}
            >
              {_t("Common.edit")}
            </Button>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { openGiftModal };

export default connect(mapStateToProps, mapDispatchToProps)(MyGiftsTable);
