export const regulamin_pl = {
  title: "Regulamin i Polityka Prywatności",
  message: "Zakładając konto akceptujesz",

  1: "Postanowienia ogólne",
  "1-1":
    'Niniejszy Regulamin określa zasady korzystania z serwisu internetowego Gifteeme.pl, prowadzącego przez Łukasz Parda Parda Soft NIP: [nr 7582297495] zwanego dalej "Usługodawcą".',
  "1-2":
    "Kontakt z Usługodawcą możliwy jest za pośrednictwem adresu e-mail: lukasz.parda@gmail.com.",
  "1-3":
    "Każdy użytkownik, zakładając konto w serwisie Gifteeme.pl, zobowiązuje się do przestrzegania postanowień niniejszego Regulaminu.",
  "1-4":
    "Założenie konta w serwisie jest równoznaczne z akceptacją niniejszego Regulaminu.",

  2: "Zasady korzystania z serwisu",
  "2-1":
    "Serwis Gifteeme.pl umożliwia użytkownikom tworzenie list prezentowych oraz udostępnianie ich innym osobom poprzez link URL.",
  "2-2":
    "Jedynymi danymi wymaganymi do utworzenia konta jest adres e-mail, który pełni rolę nazwy użytkownika.",
  "2-3": "Użytkownik zobowiązuje się do:",
  "2-3-a":
    "podawania prawdziwego i aktualnego adresu e-mail podczas rejestracji,",
  "2-3-b":
    "nieumieszczania treści niezgodnych z prawem, obraźliwych, wulgarnych lub naruszających prawa osób trzecich,",
  "2-3-c": "nieudostępniania swojego konta innym osobom.",
  "2-4":
    "Użytkownik ponosi pełną odpowiedzialność za treści zamieszczane w serwisie, w tym za dodawane linki, opisy czy zdjęcia.",
  "2-5":
    "Usługodawca zastrzega sobie prawo do usunięcia treści niezgodnych z Regulaminem lub zablokowania konta użytkownika, który narusza postanowienia Regulaminu.",

  3: "Odpowiedzialność Usługodawcy",

  "3-1": "Usługodawca nie ponosi odpowiedzialności za:",
  "3-1-a": "treści umieszczane przez użytkowników serwisu,",
  "3-1-b":
    "działania użytkowników naruszające prawo lub postanowienia niniejszego Regulaminu,",
  "3-1-c":
    "ewentualne przerwy w działaniu serwisu spowodowane awariami technicznymi lub innymi zdarzeniami losowymi.",
  "3-2":
    "Usługodawca zastrzega sobie prawo do zakończenia działalności serwisu Gifteeme.pl w dowolnym momencie, bez obowiązku archiwizacji danych użytkowników.",
  "3-3":
    "W przypadku usunięcia konta przez użytkownika, wszelkie dane i zasoby powiązane z kontem zostaną trwale usunięte i nie będą podlegać przywróceniu.",

  4: "Procedura zakładania i usuwania konta",

  "4-1":
    "Rejestracja w serwisie odbywa się poprzez podanie adresu e-mail i utworzenie hasła.",
  "4-2":
    "Użytkownik może w dowolnym momencie usunąć swoje konto za pośrednictwem opcji dostępnej w ustawieniach konta.",
  "4-3":
    "Usunięcie konta jest równoznaczne z trwałym usunięciem wszystkich danych i zasobów powiązanych z kontem.",

  5: "Prawa autorskie i własność intelektualna",

  "5-1":
    "Serwis oraz wszystkie jego elementy, takie jak nazwa, logo, szata graficzna, podlegają ochronie prawnej i stanowią własność Usługodawcy.",
  "5-2":
    "Zabrania się kopiowania, modyfikowania lub rozpowszechniania elementów serwisu bez uprzedniej zgody Usługodawcy.",

  6: "Polityka Prywatności",

  "6-1":
    "Serwis Gifteeme.pl przetwarza jedynie adres e-mail użytkownika podany podczas rejestracji w celu:",
  "6-1-a": "utworzenia i obsługi konta użytkownika,",
  "6-1-b":
    "wysyłania powiadomień o zmianach na listach prezentowych, rezerwacjach prezentów oraz zmianach w ustawieniach konta (np. zmiana hasła).",
  "6-2":
    "Dane użytkownika nie są udostępniane podmiotom trzecim, chyba że wymagają tego przepisy prawa.",
  "6-3":
    "Usługodawca podejmuje wszelkie niezbędne środki techniczne i organizacyjne w celu ochrony danych osobowych użytkowników.",
  "6-4": "Użytkownik ma prawo do:",
  "6-4-a": "wglądu w swoje dane,",
  "6-4-b": "żądania ich sprostowania lub usunięcia,",
  "6-4-c": "ograniczenia przetwarzania swoich danych.",
  "6-5":
    "Szczegółowe informacje dotyczące przetwarzania danych osobowych znajdują się w Polityce Prywatności serwisu.",

  7: "Postanowienia końcowe",

  "7-1":
    "Niniejszy Regulamin obowiązuje od dnia jego opublikowania w serwisie Gifteeme.pl.",
  "7-2":
    "Usługodawca zastrzega sobie prawo do zmiany Regulaminu w dowolnym czasie. O zmianach użytkownicy będą informowani poprzez publikację nowej wersji Regulaminu w serwisie.",
  "7-3":
    "W sprawach nieuregulowanych w Regulaminie obowiązują przepisy prawa polskiego.",
  "7-4":
    "Wszelkie spory powstałe na tle niniejszego Regulaminu będą rozpatrywane przez sąd właściwy dla siedziby Usługodawcy.",
};
