import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { closeLoginModal } from "../../../store/actions/modals";
import { reduxLogin } from "../../../store/actions/auth";
import { connect } from "react-redux";
import { primary } from "../../../shared/colors";
import CssTextField from "../../../components/CssTextField";
import { validateEmail } from "../../../shared/utils";
import axiosClient from "../../../api-client";
import { TOKEN_KEY } from "../../../conf";
import MobileWrapper, { useMobile } from "../../../components/MobileWrapper";
import { IoClose } from "react-icons/io5";
import PasswordField from "../../../components/PasswordField";

const Spacer = ({ height = 0, width = 0 }) => (
  <div style={{ height: height, width: width }} />
);

const Container = styled("div")`
  width: 400px;
  padding: 10px;
`;

const Title = styled("div")`
  text-align: center;
  color: ${primary.main};
  font-family: "FairProsper";
  font-size: 40px;
  padding: 25px;
`;

const LoginForm = styled("div")`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const ForgotPasswordButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
`;

const Login = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mobileWidthSx } = useMobile();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [emailValidationError, setEmailValidationError] = useState(false);
  const [wrongEmailOrPassword, setWrongEmailOrPassword] = useState(false);

  const { mutate, isLoading } = useMutation(TOKEN_KEY, () => {
    const data = { username: username, password: password };
    setWrongEmailOrPassword(false);
    axiosClient
      .post(`/api/v1/auth/token/`, data)
      .then((response) => {
        const { token, user, is_active } = response.data;
        localStorage.setItem(TOKEN_KEY, token);
        props.reduxLogin(token, user);
        props.closeLoginModal();
        const newPath = is_active === false ? "/activation-required" : "/";
        window.location.href = newPath;
        return token;
      })
      .catch(() => setWrongEmailOrPassword(true));
  });

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setEmailValidationError(!validateEmail(value));
    setUsername(value);
  };

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleEnterClick = (e) => (e.key === "Enter" ? mutate() : null);

  const handleCreateAccountClick = () => {
    props.closeLoginModal();
    navigate("/create-new-account");
  };

  const handlePasswordRecoverClick = () => {
    props.closeLoginModal();
    navigate("/password-recovery");
  };

  return (
    <Container sx={mobileWidthSx}>
      <MobileWrapper
        component={
          <IconButton sx={{ float: "right" }} onClick={props?.onClose}>
            <IoClose />
          </IconButton>
        }
      />
      <Title>Gifteeme</Title>
      <LoginForm>
        <CssTextField
          autoFocus
          error={emailValidationError}
          aria-label="login-username-input"
          focusColor="black"
          placeholder={t("Login.username")}
          label={t("Login.username")}
          value={username}
          onChange={handleUsernameChange}
          onKeyDown={handleEnterClick}
          sx={{ mb: 3 }}
        />
        <PasswordField
          component={CssTextField}
          aria-label="login-password-input"
          focusColor="black"
          placeholder={t("Login.password")}
          label={t("Login.password")}
          value={password}
          onChange={handlePasswordChange}
          onKeyDown={handleEnterClick}
          sx={{ mb: 3 }}
        />
        {!!wrongEmailOrPassword && (
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="error"
          >
            {t("Login.wrongEmailOrPassword")}
          </Typography>
        )}
        <Spacer height={20} sx={{ backgroundColor: "red" }} />
        <Button
          aria-label="login-button"
          variant="contained"
          onClick={mutate}
          sx={{ mb: 2 }}
        >
          {t("Login.sign_in")}
        </Button>
        <ForgotPasswordButton
          onClick={handlePasswordRecoverClick}
          sx={{ color: "text.primary", mb: 1 }}
        >
          {t("Login.forgot_password")}
        </ForgotPasswordButton>
        <Divider />
        <Button
          onClick={handleCreateAccountClick}
          sx={{ mt: 1 }}
          disabled={isLoading}
        >
          {t("Login.create_account")}
        </Button>
      </LoginForm>
    </Container>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { closeLoginModal, reduxLogin };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
export const _Login = Login;
