import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMobile } from "../MobileWrapper";

export default function ConfirmationModal({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = null,
  cancelText = null,
}) {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullScreen={isMobile}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: isMobile ? "auto" : "400px" }}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {cancelText || t("Common.cancel")}
        </Button>
        <Button onClick={onConfirm} autoFocus>
          {confirmText || t("Common.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
