import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import StyledFlex from "../components/StyledFlex";

const ReadyQuestion = (props) => {
  const { t } = useTranslation();
  const { handleGenerateLinkClick } = props;

  return (
    <StyledFlex>
      <Typography>{t("GiftList.readyQuestion")}</Typography>

      <Button size="small" variant="outlined" onClick={handleGenerateLinkClick}>
        {t("GiftList.share")}
      </Button>
    </StyledFlex>
  );
};

export default ReadyQuestion;
