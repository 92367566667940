import axiosClient from "./api-client";
import { TOKEN_KEY } from "./conf";

const AuthorizationProvider = (props) => {
  const token = localStorage.getItem(TOKEN_KEY);

  axiosClient.interceptors.request.use((config) => {
    if (token) config.headers.Authorization = `Token ${token}`;
    return config;
  });
  if (!token) return null;
  return props.children;
};

export default AuthorizationProvider;
