import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import { Button, Divider, Typography } from "@mui/material";
import StyledFlex from "../components/StyledFlex";
import ActivationDesclaimer from "./ActivationDesclaimer";
import ResendActivationLinkModal from "./ResendActivationLinkModal";
import { useState } from "react";
import { useMobile } from "../components/MobileWrapper";
import { FaHome } from "react-icons/fa";

const InnerContainer = styled("div")`
  margin: auto;
  width: 500px;
  padding-bottom: 200px;
`;

const ActivationPage = (props) => {
  const { t } = useTranslation();
  const { mobileWidthSx } = useMobile();
  const [resendMoalOpen, setResendModalOpen] = useState(false);

  return (
    <InnerContainer sx={mobileWidthSx}>
      <Typography variant={"h4"} component={"div"}>
        {t("Activate.activateAccount")}
      </Typography>
      <br />
      <Typography>{t("Activate.thankYou")}</Typography>
      <Typography mt="10px">{t("Activate.text")}</Typography>
      <br />
      <StyledFlex sx={{ justifyContent: "flex-start" }}>
        <Typography sx={{ fontWeight: 400 }}>
          {t("Activate.didNotReceive")}
        </Typography>
        &nbsp;
        <Button
          size="small"
          color="black"
          sx={{ textTransform: "none", fontSize: "15px" }}
          onClick={() => setResendModalOpen(true)}
        >
          {t("Activate.sendAgain")}
        </Button>
      </StyledFlex>
      <br />
      <Button
        startIcon={<FaHome />}
        fullWidth
        variant="contained"
        onClick={() => (window.location.href = "/")}
      >
        {t("Activate.backToHomePage")}
      </Button>
      <Divider sx={{ mt: "20px" }} />
      <ActivationDesclaimer />
      <ResendActivationLinkModal
        open={resendMoalOpen}
        onClose={() => setResendModalOpen(false)}
      />
    </InnerContainer>
  );
};

export default ActivationPage;
