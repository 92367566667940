import { Button, Menu, MenuItem, styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineCheck } from "react-icons/ai";

const StyledButton = styled(Button)`
  color: #fff;
`;
const StyledMenuItem = styled(MenuItem)`
  min-width: 70px;
  display: flex;
  justify-content: space-between;
`;

const LANGUAGES = ["pl", "en"];

const LanguageButton = (props) => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLanguageClick = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    handleMenuClose();
  };

  return (
    <>
      <StyledButton
        aria-label="language-button"
        onClick={handleLanguageClick}
        endIcon={<RiArrowDropDownLine />}
      >
        {i18n.language}
      </StyledButton>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
        {LANGUAGES.map((lang) => {
          const isCurrent = i18n.language === lang;
          return (
            <StyledMenuItem
              key={lang}
              aria-label={`language-${lang}`}
              selected={isCurrent}
              onClick={(e) => handleLanguageChange(lang)}
            >
              {lang}
              {isCurrent ? <AiOutlineCheck /> : <div />}
            </StyledMenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default LanguageButton;
