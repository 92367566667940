import { Button, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { openLoginModal } from "../store/actions/modals";

const StyledButton = styled(Button)`
  color: #fff;
`;

const LoginButton = (props) => {
  const { t } = useTranslation();
  return (
    <StyledButton aria-label="login-button" onClick={props.openLoginModal}>
      {t("Login.sign_in")}
    </StyledButton>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  openLoginModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
export const _LoginButton = LoginButton;
