import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useMobile } from "../components/MobileWrapper";
import PasswordField from "../components/PasswordField";
import ConfirmationModal from "../components/ConfirmationModal";
import { useState } from "react";
import { useAccount } from "../hooks";

export default function DeleteAccountDialog(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const { t: _t } = useTranslation(undefined, { keyPrefix: "DjangoErrors" });
  const { isMobile } = useMobile();
  const { deleteAccount } = useAccount();

  const [password, setPassword] = useState(null);
  const [checked, setChecked] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const _onClose = () => {
    deleteAccount.reset();
    setPassword(null);
    setChecked(false);
    onClose();
  };
  const errors = deleteAccount.error?.response?.data || {};

  const handleDeleteAccount = () =>
    deleteAccount
      .mutateAsync({ password })
      .then(() => window.location.reload());

  return (
    <Dialog open={open} onClose={_onClose} fullScreen={isMobile}>
      <DialogTitle>{t("MyAccount.deleteAccount.title")}</DialogTitle>
      <DialogContent sx={{ maxWidth: "500px" }}>
        <Typography>{t("MyAccount.deleteAccount.text1")}</Typography>
        <br />
        <PasswordField
          fullWidth
          sx={{ m: "5px 0px" }}
          label={t("MyAccount.passwordChange.confirmNewPassword")}
          value={password ?? ""}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
          helperText={_t(errors.password, "")}
        />
        <br />
        <br />
        <Typography>
          <Trans
            i18nKey={t("MyAccount.deleteAccount.text2")}
            components={{
              0: <li />,
              1: <li />,
              2: <li />,
              3: <b />,
              4: <ul />,
            }}
          />
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={() => setChecked((prevState) => !prevState)}
            />
          }
          label={t("MyAccount.deleteAccount.checkbox")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color="black">
          {t("Common.cancel")}
        </Button>
        <Button
          onClick={() => setConfirmationOpen(true)}
          disabled={!password || !checked}
        >
          {t("MyAccount.deleteAccount.title")}
        </Button>
      </DialogActions>
      <ConfirmationModal
        open={!!confirmationOpen}
        title={t("MyAccount.deleteAccount.confirmationTitle")}
        confirmText={t("MyAccount.deleteAccount.confirmationText")}
        onClose={() => setConfirmationOpen(null)}
        onConfirm={() => {
          setConfirmationOpen(false);
          handleDeleteAccount();
        }}
      />
    </Dialog>
  );
}
