import { Box, Button, Dialog, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInviteeList } from "../hooks";
import { FaPlus } from "react-icons/fa";
import GiftForm from "../modals/GiftModal/GiftForm";
import { useState } from "react";
import { useMobile } from "../components/MobileWrapper";

export default function AllowGiftProposition() {
  const { t } = useTranslation();
  const { inviteLink } = useParams();
  const { data, _createGiftProposition } = useInviteeList(inviteLink);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isMobile } = useMobile();

  const handleNewGiftSubmit = (data) =>
    _createGiftProposition.mutateAsync(data).then(() => setDialogOpen(false));

  if (!data?.allow_gift_propositions) return null;

  return (
    <Box>
      <Tooltip
        title={<Typography>{t("InviteeList.addGiftTooltip")}</Typography>}
      >
        <Box ml={1}>
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="green"
            startIcon={<FaPlus size="14px" />}
            onClick={() => setDialogOpen(true)}
          >
            {t("InviteeList.addGift")}
          </Button>
        </Box>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullScreen={isMobile}
      >
        <GiftForm
          gift={{}}
          onSubmit={handleNewGiftSubmit}
          onCancel={() => setDialogOpen(false)}
        />
      </Dialog>
    </Box>
  );
}
