import { SET_LOADING_STATUS } from "../actions/loading";

export const loadingReducer = (
  state = {
    status: false,
  },
  action
) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return {
        ...state,
        status: action.data.status,
      };
    default:
      return state;
  }
};
