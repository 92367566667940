import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { FaShoppingBag } from "react-icons/fa";
import ConfirmationModal from "../components/ConfirmationModal";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { useInviteeList } from "../hooks";
import { useParams } from "react-router-dom";
import StyledFlex from "../components/StyledFlex";

const GiftCards = (props) => {
  const { t } = useTranslation();
  const { showOnlyAvailable } = props;
  const { inviteLink } = useParams();
  const { data } = useInviteeList(inviteLink);
  const { _reserve } = useInviteeList(inviteLink);

  const [reserveConfirmationGiftId, setReserveConfirmationGiftId] =
    useState(null);

  const gifts = (data?.gifts || []).filter((g) =>
    showOnlyAvailable ? !g.reserved : g
  );

  return (
    <Box>
      <Typography sx={{ fontWeight: 500 }}>
        {t("GiftList.gifts.title")}
      </Typography>
      {gifts?.map((gift) => {
        return (
          <Card
            key={gift?.id}
            sx={{ m: "20px -30px", border: "1px solid #e0e0e0" }}
            elevation={2}
          >
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                {gift?.name || ""}
              </Typography>
            </CardContent>
            {!!gift.image_link && (
              <CardMedia
                sx={{ height: "280px" }}
                image={gift?.image_link}
                component={
                  !gift?.image_link
                    ? () => <div style={{ height: "280px" }} />
                    : null
                }
                title=""
              />
            )}
            {!gift.image_link && (
              <StyledFlex
                bgcolor="#fafafa"
                height="280px"
                sx={{ justifyContent: "center", opacity: 0.5 }}
              >
                No image
              </StyledFlex>
            )}
            <CardContent>
              <Typography>{gift?.price_range || ""}</Typography>
            </CardContent>
            <Box sx={{ p: "5px" }}>
              <Button
                variant="contained"
                color="green"
                sx={{ mb: "5px" }}
                fullWidth
                startIcon={<FaShoppingBag size={18} />}
                onClick={() => window.open(gift?.buy_link || "", "_blank")}
                disabled={!gift.buy_link}
              >
                {t("InviteeGiftsCard.shop")}
              </Button>
              {!!gift.reserved ? (
                <Button fullWidth>{t("InviteeGiftsCard.reserved")}</Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setReserveConfirmationGiftId(gift.id)}
                >
                  {t("InviteeList.reserveButton")}
                </Button>
              )}
            </Box>
          </Card>
        );
      })}
      <ConfirmationModal
        open={!!reserveConfirmationGiftId}
        onClose={() => setReserveConfirmationGiftId(null)}
        onConfirm={() =>
          _reserve
            .mutateAsync(reserveConfirmationGiftId)
            .then(() => setReserveConfirmationGiftId(null))
        }
        title={t("InviteeList.reserveConfirmation")}
        message={<Trans i18nKey={"InviteeList.reserveConfirmationMessage"} />}
        confirmText={t("InviteeList.reserveButton")}
      />
    </Box>
  );
};

export default GiftCards;
