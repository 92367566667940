export const regulamin_en = {
  title: "Terms and regulations",
  message: "By creating an account you accept",

  1: "General provisions",

  "1-1":
    "This Regulation defines the rules for using the Gifteeme.pl website, run by Łukasz Parda Parda Soft NIP: [nr 7582297495], hereinafter referred to as the 'Service Provider'.",
  "1-2":
    "Contact with the Service Provider is possible via e-mail: lukasz.parda@gmail.com.",
  "1-3":
    "Each user, by creating an account on the Gifteeme.pl website, undertakes to comply with the provisions of this Regulation.",
  "1-4":
    "Creating an account on the website is tantamount to accepting this Regulation.",

  2: "Rules of using the service",

  "2-1":
    "The Gifteeme.pl service allows users to create gift lists and share them with others via a URL link.",
  "2-2":
    "The only data required to create an account is an e-mail address, which serves as the username.",
  "2-3": "The user undertakes to:",
  "2-3-a": "provide a true and current e-mail address during registration,",
  "2-3-b":
    "not to place content that is illegal, offensive, vulgar or infringes the rights of third parties,",
  "2-3-c": "not to share their account with other people.",
  "2-4":
    "The user is fully responsible for the content posted on the website, including added links, descriptions or photos.",
  "2-5":
    "The Service Provider reserves the right to remove content that is inconsistent with the Regulations or to block the account of a user who violates the provisions of the Regulations.",

  3: "Service Provider's liability",

  "3-1": "The Service Provider is not responsible for:",
  "3-1-a": "content posted by users of the service,",
  "3-1-b":
    "actions of users violating the law or the provisions of this Regulation,",
  "3-1-c":
    "any interruptions in the operation of the service caused by technical failures or other random events.",
  "3-2":
    "The Service Provider reserves the right to terminate the operation of the Gifteeme.pl service at any time, without the obligation to archive user data.",
  "3-3":
    "In the event of an account being deleted by the user, all data and resources associated with the account will be permanently deleted and will not be recoverable.",

  4: "Account creation and deletion procedure",

  "4-1":
    "Registration on the service is done by providing an e-mail address and creating a password.",

  "4-2":
    "The user can delete their account at any time through the option available in the account settings.",
  "4-3":
    "Deleting an account is tantamount to permanently deleting all data and resources associated with the account.",

  5: "Copyright and intellectual property",

  "5-1":
    "The service and all its elements, such as the name, logo, graphic design, are protected by law and are the property of the Service Provider.",
  "5-2":
    "Copying, modifying or distributing elements of the service without the prior consent of the Service Provider is prohibited.",

  6: "Privacy Policy",

  "6-1":
    "The Gifteeme.pl service processes only the user's e-mail address provided during registration for:",
  "6-1-a": "creating and managing a user account,",
  "6-1-b":
    "sending notifications about changes to gift lists, gift reservations and changes to account settings (e.g. changing a password).",
  "6-2": "User data is not shared with third parties, unless required by law.",
  "6-3":
    "The Service Provider takes all necessary technical and organizational measures to protect user data.",
  "6-4": "The user has the right to:",
  "6-4-a": "access their data,",
  "6-4-b": "rectify or delete them,",
  "6-4-c": "limit the processing of their data.",
  "6-5":
    "Detailed information on the processing of personal data is available in the Privacy Policy of the service.",

  7: "Final provisions",

  "7-1":
    "This Regulation is effective from the date of its publication on the Gifteeme.pl website.",
  "7-2":
    "The Service Provider reserves the right to change the Regulation at any time. Users will be informed about changes by publishing a new version of the Regulation on the website.",
  "7-3":
    "In matters not regulated in the Regulations, the provisions of Polish law shall apply.",
  "7-4":
    "Any disputes arising from this Regulation will be considered by the court competent for the Service Provider's seat.",
};
