import { styled } from "@mui/material";

const Container = styled("div")`
  height: calc(100vh - 200px);
  margin: auto;
  width: 70%;
  margin-top: 150px;
  @media only screen and (max-width: 600px) {
    width: 95%;
  }
`;

export default Container;
