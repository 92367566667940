import { regulamin_en } from "./en-terms";

const translation = {
  Home: {
    welcome: "Welcome to Gifteeme.pl",
    signIn: "Sign in and create your first gift list.",
  },
  Login: {
    username: "E-mail address",
    password: "Password",
    sign_in: "Sign in",
    forgot_password: "Forgot password?",
    create_account: "Create new account",
    logout: "Logout",
    wrongEmailOrPassword: "Wrong e-mail or password",
  },
  Register: {
    email: "E-mail address",
    password: "Password",
    confirmPassword: "Confirm password",
    createAccount: "Create new account",
    back: "Cancel",
    success: "New account successfully created",
  },
  Recover: {
    recoverAccount: "Recover password",
    success: "New Password successfully generated.",
    back: "Cancel",
    text1: "To recover your password",
    text2: "Enter the e-mail address of your account.",
    text3: "We will send you a new password to the provided e-mail address.",
    text4: "Remember to change your password after logging in.",
  },
  Activate: {
    thankYou: "Thank you for registering!",
    text: "We have sent an email with activation link  to the email address you provided during the registration process.",
    email: "E-mail address",
    resendModalTitle: "Resend activation link",
    resendModalText:
      "Enter your email address and click 'Send' to receive a new activation link.",
    activateAccount: "Activate account",
    didNotReceive: "Did not receive the code?",
    codeSent: "The code has been sent to your email address.",
    sendAgain: "Send again",
    backToHomePage: "Back to home page",
    desclaimer: {
      main: "In case you did not receive the code:",
      1: {
        main: "Check Spam Folder",
        description:
          "Sometimes, activation emails may be mistakenly filtered to your spam or junk folder. Please check those folders if you haven't received the email within a few minutes.",
      },
      2: {
        main: "Wait for a Few Minutes",
        description:
          "Occasionally, email delivery may be delayed due to network congestion or other factors. Please wait for a few minutes before requesting a new activation link.",
      },
      3: {
        main: "Contact Support",
        description:
          "If you have checked your spam folder and waited for an appropriate amount of time but still haven't received the email, please contact our support team for assistance.",
      },
    },
    accountActivated: "Account activated! Please log in.",
  },
  ProfileMenu: {
    logout: "Logout",
    myAccount: "My account",
    help: "Help",
  },
  Drawer: {
    hello: "Hello",
    createNewList: "Create new list",
    myLists: "My lists",
    noLists: "No lists",
  },
  NewListForm: {
    header: "New List",
    form: {
      name: "Name",
      description: "Description",
      submitButton: "Create",
      cancelButton: "Back",
    },
  },
  GiftList: {
    giftsProposition: "You have {{count}} gifts proposition",
    allowGiftProposition: "Allow gift propositions",
    allowGiftPropositionInfo:
      "Allow your friends to propose gifts that are not on the list. You will be able to accept or reject them.",
    deleteButton: "Delete",
    deleteConfirmation: "Confirm deletion",
    deleteConfirmationMessage:
      "Are you sure you want to delete the selected list? <br/>This operation cannot be undone.",
    changeButton: "Change",
    ready: "The list is ready",
    copied: "Copied",
    copy: "Copy link",
    readyQuestion: "Is the list ready?",
    share: "Share it with your friends",
    goToList: "Go to list",
    gifts: {
      title: "Gifts:",
      noData: "No data",
      createNewGift: "Add Gift",
      ordered: "Ordered",
    },
    generateLinkConfirmationMessage:
      "You will generate a link to your list. Share it with your friends so they can start booking gifts. <br/><br/>You can still edit and add new gifts. <br/>Editing and deleting will be blocked only for reserved gifts.",
  },
  NewGiftForm: {
    titleNew: "Add gift",
    form: {
      name: "Name",
      description: "Description",
      priceRange: "Price range",
      buyLink: "Buy link",
      imageLink: "Image link",
      createButton: "Create",
      saveButton: "Save",
      cancelButton: "Cancel",
      deleteButton: "Delete",
      deleteConfirmation: "Are you sure?",
    },
    deleteConfirmation: "Please confirm your choice",
    deleteConfirmationMessage:
      "Are you sure you want to delete the selected gift? <br/>This operation cannot be undone.",
  },
  ChangeList: {
    name: "Name",
    description: "Description",
    cancelButton: "Cancel",
    submitButton: "Submit",
  },
  InviteeList: {
    showReserved: "Show only available gifts",
    addGift: "Add gift",
    giftPropositionSentToast: "Gift proposition sent",
    giftReservedToast: "Gift reserved",
    addGiftTooltip:
      "Your proposal will be added to the list after being accepted by the list owner.",
    reserveButton: "Reserve",
    reserveConfirmation: "Confirm reservation",
    reserveConfirmationMessage:
      "The gift will be marked as reserved. <br/>Are you sure you want to continue? This operation cannot be undone.",
  },
  warnings: {
    fieldRequired: "This field is required",
    maxLength: "Text too long. Max length: ",
  },
  MyGiftsTable: {
    noGifts: "No gifts",
    shop: "Shop",
    reserved: "Reserved",
    available: "Available",
    approveConfirmation: "Approve Proposed Gift",
    approveConfirmationMessage:
      "Once approved, this gift will be available for your friends to reserve.<br/>Are you sure you want to approve it?",
    columns: {
      giftName: "Gift",
      price: "Price",
      store: "Buy here",
      image: "Photo",
      reserved: "Reserved",
      approve: "Approve",
    },
  },
  InviteeGiftsTable: {
    shop: "Shop",
    reserved: "Reserved",
    columns: {
      giftName: "Gift",
      price: "Price",
      store: "Buy here",
      image: "Photo",
      reserve: "Reserve",
    },
  },
  InviteeGiftsCard: {
    shop: "Shop",
    reserve: "Reserve",
    reserved: "Reserved",
  },
  MyAccount: {
    myAccount: "My account",
    passwordChange: {
      title: "Change password",
      oldPassword: "Old password",
      newPassword: "New password",
      confirmNewPassword: "Confirm new password",
      success: "Password successfully changed",
      confirmationTitle: "Change password",
      confirmationMessage: "Are you sure you want to change your password?",
    },
    accountDetails: {
      title: "Account details",
      email: "E-mail",
      created: "Account created",
      lists: "Number of created lists",
      gifts: "Number of added gifts",
    },
    termsAndRegulations: regulamin_en,
    deleteAccount: {
      title: "Delete account",
      confirmationTitle: "Confirm account deletion",
      confirmationText: "Confirm",
      checkbox: "I confirm that I want to delete my account",
      text1:
        "Confirm your identity by providing your password to continue deleting your account.",
      text2:
        "After deleting the account: <4><0>All your data and saved information will be permanently deleted.</0><1>You will lose access to your profile and activity history.</1><2>This operation is irreversible. <3>There will be no possibility of data recovery.</3></2><4/>",
    },
  },
  Help: {
    title: "Help",
    header: "Do you have any questions or need help?",
    text: "If you have encountered a problem or have any questions, please contact us using the contact form available on the website. We will try to respond to your request as soon as possible!",
    label: "Message",
    messageSent: "Message sent",
    send: "Send message",
  },
  Cookies: {
    title: "We use cookies",
    text: "Our application uses only necessary cookies to work correctly. By continuing to use the application, you agree to their use.",
    acceptAndClose: "Accept and close",
  },
  Common: {
    invalidEmail: "Invalid email address",
    no: "Nie",
    yes: "Tak",
    accept: "Accept",
    confirm: "Confirm",
    cancel: "Cancel",
    ok: "Ok",
    back: "Back",
    attention: "Attention",
    send: "Send",
    edit: "Edit",
  },
  DjangoErrors: {
    invalid: "Invalid data",
    "invalid-code": "Invalid code.",
    "invalid-user": "Invalid user",
    "email-required": "Email is required.",
    "wait-for-another-gift-proposition":
      "You have to wait 1 minute before sending another gift proposition.",
    "password-needs-to-be-at-least-8-chars-long-contain-a-capital-letter-and-a-numer":
      "Password needs to be at least 8 characters long, contain a capital letter and a number.",
    "confirmation-password-and-password-differs":
      "Confirmation password and password differs.",
    "old-password-is-incorrect": "Old password is incorrect.",
    "password-is-incorrect": "Password is incorrect.",
    "invalid-email": "Invalid e-mail address.",
    "A user with that username already exists.":
      "A user with that username already exists.",
    "wait-for-another-help-message":
      "You can only send one help message per minute",
  },
};

export default translation;
