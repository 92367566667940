import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFlex = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default StyledFlex;
