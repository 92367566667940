import {
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMyLists } from "../hooks";
import { useState } from "react";

export default function AllowGiftPropositionSwitch(props) {
  const { list } = props;
  const { t } = useTranslation();
  const { _update } = useMyLists();

  const [allow, setAllow] = useState(list.allow_gift_propositions);

  const handleChange = () => {
    const _allow = !allow;
    setAllow(_allow);
    _update
      .mutateAsync({ ...list, allow_gift_propositions: _allow })
      .catch(() => setAllow(!_allow));
  };

  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={allow} onChange={handleChange} />}
        label={t("GiftList.allowGiftPropositionInfo")}
      />
    </Box>
  );
}
