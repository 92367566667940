import React from "react";
import { connect } from "react-redux";
import { getSelfUser } from "../store/actions/auth";
import { TOKEN_KEY } from "../conf";
import axiosClient from "../api-client";

const OptionalAuthorization = (props) => {
  const { getSelfUser } = props;
  React.useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosClient.interceptors.request.use((config) => {
        if (token) config.headers.Authorization = `Token ${token}`;
        return config;
      });
      getSelfUser();
    }
  }, [getSelfUser]);
  return props.children;
};

const mapStateToProps = (state) => ({ user: state.auth.user });
const mapDispatchToProps = { getSelfUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionalAuthorization);
