import { useEffect } from "react";
import { connect } from "react-redux";
import { getSelfUser } from "./store/actions/auth";

const InitialRequest = (props) => {
  useEffect(() => props.getSelfUser(), [props]);
  return <div />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getSelfUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialRequest);
