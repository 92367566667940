import {
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordChangeDialog from "./PasswordChangeDialog";
import AccountDetailsDialog from "./AccountDetailsDialog";
import TermsAndRegulationsDialog from "./TermsAndRegulationsDialog";
import { BsBookHalf } from "react-icons/bs";
import { MdAccountCircle, MdDelete } from "react-icons/md";
import Item from "./Item";
import { FaUnlock } from "react-icons/fa";
import DeleteAccountDialog from "./DeleteAccountDialog";

export default function MyAccount() {
  const { t } = useTranslation(undefined, { keyPrefix: "MyAccount" });
  const [accountDetailsOpen, setAccountDetailsOpen] = useState(false);
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const [termsAndRegulationsOpen, setTermsAndRegulationsOpen] = useState(false);
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);
  return (
    <Box>
      <h2>{t("myAccount")}</h2>
      <br />
      <Box bgcolor="#fff" border="1px solid #e0e0e0">
        <Item
          onClick={() => setAccountDetailsOpen(true)}
          icon={<MdAccountCircle />}
          text={t("accountDetails.title")}
        />
        <Divider style={{ margin: 0 }} />
        <Item
          onClick={() => setTermsAndRegulationsOpen(true)}
          icon={<BsBookHalf />}
          text={t("termsAndRegulations.title")}
        />
        <Divider style={{ margin: 0 }} />
        <Item
          onClick={() => setPasswordChangeOpen(true)}
          icon={<FaUnlock />}
          text={t("passwordChange.title")}
        />
      </Box>
      <br />
      <Box bgcolor="#fff" border="1px solid #e0e0e0">
        <Item
          onClick={() => setDeleteAccountOpen(true)}
          icon={<MdDelete color="#d32f2f" />}
          text={
            <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
              {t("deleteAccount.title")}
            </Typography>
          }
        />
      </Box>
      <AccountDetailsDialog
        open={accountDetailsOpen}
        onClose={() => setAccountDetailsOpen(false)}
      />
      <TermsAndRegulationsDialog
        open={termsAndRegulationsOpen}
        onClose={() => setTermsAndRegulationsOpen(false)}
      />
      <PasswordChangeDialog
        open={passwordChangeOpen}
        onClose={() => setPasswordChangeOpen(false)}
      />
      <DeleteAccountDialog
        open={deleteAccountOpen}
        onClose={() => setDeleteAccountOpen(false)}
      />
    </Box>
  );
}
