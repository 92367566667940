import { useEffect, useState } from "react";
import { MOBILE_WIDTH } from "../shared/settings";

export const useMobile = (width = undefined) => {
  const [_width, _setWidth] = useState(window.innerWidth);
  const compareWidth = width || MOBILE_WIDTH;
  const handleWindowSizeChange = () => _setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = _width <= compareWidth;
  
  const mobileWidthSx = isMobile ? { width: "auto" } : {};

  return { isMobile, mobileWidthSx };
};

const MobileWrapper = (props) => {
  const { isMobile } = useMobile(props.width);
  if (isMobile) return props.component || <div />;
  return props.children || null;
};

export default MobileWrapper;
