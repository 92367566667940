import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMobile } from "../components/MobileWrapper";
import { connect } from "react-redux";
import { useMyGifts, useMyLists } from "../hooks";

const AccountDetailsDialog = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { data: lists } = useMyLists();
  const { data: gifts } = useMyGifts();

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>{t("MyAccount.accountDetails.title")}</DialogTitle>
      <DialogContent>
        <Typography>{t("MyAccount.accountDetails.created")}: {new Date(props.user.date_joined).toLocaleString()}</Typography>
        <Typography>{t("MyAccount.accountDetails.email")}: {props.user.email}</Typography>
        <br/>
        <Typography>{t("MyAccount.accountDetails.lists")}: {lists.length}</Typography>
        <Typography>{t("MyAccount.accountDetails.gifts")}: {gifts.length}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="black">
          {t("Common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AccountDetailsDialog);
