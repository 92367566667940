export const SET_LOADING_STATUS = "SET_LOADING_STATUS";

export const setLoadingStatus =
  (status = true) =>
  (dispatch) =>
    dispatch({ type: SET_LOADING_STATUS, data: { status: status } });

export const startLoading = {
  type: SET_LOADING_STATUS,
  data: { status: true },
};

export const stopLoading = {
  type: SET_LOADING_STATUS,
  data: { status: false },
};
