import { PhotoView } from "react-photo-view";
import { FaCheckCircle, FaRegSmile, FaShoppingBag } from "react-icons/fa";
import { Button, Chip, Divider, Typography, styled } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { FiMinus } from "react-icons/fi";
import ConfirmationModal from "../components/ConfirmationModal";
import { useInviteeList } from "../hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";

const StyledPanel = styled("div")`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
`;

const StyledCol = styled("div")`
  width: ${(props) => props.width || "100%"};
  padding-right: 10px;
  text-align: ${(props) => (!!props.center ? "center" : "left")};
`;

const StyledHeaderRow = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  font-weight: 700;
`;

const StyledDataRow = styled("div")`
  min-height: 70;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
`;

const GiftTable = (props) => {
  const { showOnlyAvailable } = props;
  const { t } = useTranslation();
  const { t: _t } = useTranslation(undefined, {
    keyPrefix: "InviteeGiftsTable.columns",
  });
  const { inviteLink } = useParams();
  const { data, _reserve } = useInviteeList(inviteLink);
  const [reserveConfirmationGiftId, setReserveConfirmationGiftId] =
    useState(null);

  const _gifts = data?.gifts || [];
  const gifts = !showOnlyAvailable
    ? _gifts
    : _gifts.filter((gift) => !gift.reserved);

  return (
    <StyledPanel sx={{ pb: 20 }}>
      <div style={{ width: "100%" }}>
        <StyledHeaderRow>
          <StyledCol width="30%">{_t("giftName")}</StyledCol>
          <StyledCol width="17.5%" center>
            {_t("price")}
          </StyledCol>
          <StyledCol width="17.5%" center>
            {_t("store")}
          </StyledCol>
          <StyledCol width="17.5%" center>
            {_t("image")}
          </StyledCol>
          <StyledCol width="17.5%" center>
            {_t("reserve")}
          </StyledCol>
        </StyledHeaderRow>
        <Divider />
        {gifts.map((col) => (
          <StyledDataRow
            key={col.id}
            className="hover"
            sx={
              col.reserved && {
                backgroundColor: "#e8f5e9",
                ":hover": { backgroundColor: "#e8f5e9" },
                textDecoration: "line-through",
              }
            }
          >
            <StyledCol width="30%">
              <Typography>{col.name}</Typography>
              <small style={{ opacity: 0.7 }}>{col.description}</small>
            </StyledCol>
            <StyledCol width="17.5%" center>
              {col.price_range}
            </StyledCol>
            <StyledCol width="17.5%" center>
              {!col.buy_link && <FiMinus />}
              {col.buy_link && (
                <Button
                  variant="contained"
                  color="green"
                  startIcon={<FaShoppingBag size={18} />}
                  onClick={() => window.open(col.buy_link, "_blank")}
                  disabled={!col.buy_link}
                >
                  {t("InviteeGiftsTable.shop")}
                </Button>
              )}
            </StyledCol>
            <StyledCol width="17.5%" center>
              {!col.image_link && <FiMinus />}
              {col.image_link && (
                <PhotoView src={col.image_link}>
                  <img
                    src={col.image_link}
                    alt=""
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      cursor: "pointer",
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                  />
                </PhotoView>
              )}
            </StyledCol>
            <StyledCol width="17.5%" center>
              {col.reserved ? (
                <Chip
                  label={t("InviteeGiftsTable.reserved")}
                  avatar={<FaCheckCircle color="green" />}
                />
              ) : (
                <Chip
                  onClick={() => setReserveConfirmationGiftId(col.id)}
                  label={t("InviteeList.reserveButton")}
                  avatar={<FaRegSmile />}
                />
              )}
            </StyledCol>
          </StyledDataRow>
        ))}
      </div>
      <ConfirmationModal
        open={!!reserveConfirmationGiftId}
        onClose={() => setReserveConfirmationGiftId(null)}
        onConfirm={() =>
          _reserve
            .mutateAsync(reserveConfirmationGiftId)
            .then(() => setReserveConfirmationGiftId(null))
        }
        title={t("InviteeList.reserveConfirmation")}
        message={<Trans i18nKey={"InviteeList.reserveConfirmationMessage"} />}
        confirmText={t("InviteeList.reserveButton")}
      />
    </StyledPanel>
  );
};

export default GiftTable;
