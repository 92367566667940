import { useMutation, useQuery, useQueryClient } from "react-query";
import axiosClient from "./api-client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useMyLists = () => {
  const queryKey = ["my-lists"];
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const query = useQuery(queryKey, () =>
    axiosClient.get(`/api/v1/gifts/gift-lists/`).then((res) => res.data)
  );

  const onSuccess = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const _update = useMutation({
    mutationFn: (data) =>
      axiosClient.put(`/api/v1/gifts/gift-lists/${data.id}/`, data),
    onSuccess,
  });

  const _delete = useMutation({
    mutationFn: (listId) =>
      axiosClient.delete(`/api/v1/gifts/gift-lists/${listId}/`),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      navigate("/my-lists");
    },
  });

  return {
    ...query,
    data: query.data || [],
    _update,
    _delete,
  };
};

export const useMyGifts = () => {
  const queryClient = useQueryClient();

  const queryKey = ["my-gifts"];
  const query = useQuery(queryKey, () =>
    axiosClient.get(`/api/v1/gifts/`).then((res) => res.data)
  );

  const onSuccess = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const _create = useMutation({
    mutationFn: (data) => axiosClient.post(`/api/v1/gifts/`, data),
    onSuccess,
  });

  const _update = useMutation({
    mutationFn: (data) => axiosClient.put(`/api/v1/gifts/${data.id}/`, data),
    onSuccess,
  });

  const _delete = useMutation({
    mutationFn: (giftId) => axiosClient.delete(`/api/v1/gifts/${giftId}/`),
    onSuccess,
  });

  const _approve = useMutation({
    mutationFn: (giftId) =>
      axiosClient.post(`/api/v1/gifts/${giftId}/approve/`),
    onSuccess,
  });

  return {
    ...query,
    data: query?.data || [],
    _create,
    _update,
    _delete,
    _approve,
  };
};

export const useInviteeList = (inviteeLink = undefined) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const url = `/api/v1/invitees/list/${inviteeLink}/`;

  const query = useQuery(
    "invitee-list",
    () => axiosClient.get(url).then((res) => res.data),
    { enabled: !!inviteeLink }
  );

  const _reserve = useMutation({
    mutationFn: (giftId) => axiosClient.post(`${url}reserve/${giftId}/`),
    onSuccess: () => {
      queryClient.invalidateQueries("invitee-list");
      toast.success(t("InviteeList.giftReservedToast"));
    },
  });

  const _createGiftProposition = useMutation({
    mutationFn: (data) =>
      axiosClient.post(`${url}gift-proposition/`, {
        ...data,
        price_range: data.priceRange,
        buy_link: data.buyLink,
        image_link: data.imageLink,
      }),
    onSuccess: () => {
      toast.success(t("InviteeList.giftPropositionSentToast"));
    },
    onError: (err) => {
      const msg = t("DjangoErrors." + err.response.data, err.response.data);
      toast.error(msg);
    },
  });

  return { _reserve, _createGiftProposition, ...query };
};

export const useAccount = () => {
  const { t: _t } = useTranslation(undefined, { keyPrefix: "DjangoErrors" });
  const { t } = useTranslation();

  const activate = useMutation({
    mutationFn: (key) =>
      axiosClient.post(`/api/v1/auth/activate-account/`, { key }),
    onSuccess: () => toast(t("Activate.accountActivated")),
    onError: (err) => {
      toast.error(_t(err.response.data, "") || err.response.data);
    },
  });

  const sendAgain = useMutation({
    mutationFn: (email) =>
      axiosClient.post(`/api/v1/auth/resend-activation-link/`, { email }),
    onSuccess: () => toast(t("Activate.codeSent")),
  });

  const changePassword = useMutation({
    mutationFn: (data) =>
      axiosClient.post(`/api/v1/auth/change-password/`, data),
    onSuccess: () => toast(t("MyAccount.passwordChanged")),
    onError: (err) => {
      toast.error(_t(err.response.data, "") || err.response.data);
    },
  });

  const deleteAccount = useMutation({
    mutationFn: (data) =>
      axiosClient.post(`/api/v1/auth/delete-account/`, data),
    onSuccess: () => {
      toast(t("MyAccount.deleteAccount.success"));
    },
    onError: (err) => {
      toast.error(_t(err.response.data, "") || err.response.data);
    },
  });

  const sendHelpMessage = useMutation({
    mutationFn: (data) => axiosClient.post(`/api/v1/auth/help/`, data),
    onSuccess: (res) => {
      toast(t("Help.messageSent"));
    },
    onError: (err) => {
      toast.error(_t(err.response.data?.text, "") || err.response.data?.text);
    },
  });

  return {
    sendAgain,
    activate,
    changePassword,
    deleteAccount,
    sendHelpMessage,
  };
};
