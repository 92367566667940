import React from "react";
import {
  Box,
  Button,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Panel from "../components/Panel";
import { MdMail } from "react-icons/md";
import { useAccount } from "../hooks";

export default function Help() {
  const { t } = useTranslation(undefined, { keyPrefix: "Help" });
  const [text, setText] = React.useState(null);
  const { sendHelpMessage } = useAccount();

  const isError = text?.length > 1000;

  return (
    <Box>
      <h2>{t("title")}</h2>
      <br />
      <Panel>
        <Typography variant="h6">{t("header")}</Typography>
        <br />
        <Typography>{t("text")}</Typography>
        <br />
      </Panel>
      <br />
      <Panel>
        <Typography variant="h6">{t("send")}</Typography>
        <br />
        <TextField
          color="black"
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          label={t("label")}
          error={isError}
          helperText={`${text?.length || 0}/1000`}
          value={text ?? ""}
          onChange={(e) => setText(e.target.value)}
        />
        <DialogActions>
          <Button
            onClick={() => sendHelpMessage.mutate({ text })}
            disabled={isError || !text}
            startIcon={<MdMail />}
            variant="contained"
          >
            {t("send")}
          </Button>
        </DialogActions>
      </Panel>
    </Box>
  );
}
