import { regulamin_pl } from "./pl-terms";

const translation = {
  Home: {
    welcome: "Witaj na Gifteeme.pl",
    signIn: "Zaloguj się i stwórz swoją pierwszą listę prezentów.",
  },
  Login: {
    username: "Adres e-mail",
    password: "Hasło",
    sign_in: "Zaloguj",
    forgot_password: "Nie pamiętasz hasła?",
    create_account: "Utwórz nowe konto",
    logout: "Wyloguj",
    wrongEmailOrPassword: "Nieprawidłowy e-mail lub hasło",
  },
  Register: {
    email: "Adres e-mail",
    password: "Hasło",
    confirmPassword: "Potwierdź hasło",
    createAccount: "Stwórz nowe konto",
    back: "Wróć",
    success: "Nowe konto zostało utworzone",
  },
  Recover: {
    recoverAccount: "Odzyskaj hasło",
    success: "Nowe hasło zostało wygenerowane",
    back: "Wróć",
    text1: "Aby odzyskać hasło",
    text2: "Podaj e-mail swojego konta.",
    text3: "Nowe hasło prześlemy Ci na podany email.",
    text4: "Pamietaj, aby po ponownym zalogowaniu zmienić swoje hasło.",
  },
  Activate: {
    thankYou: "Dziękujemy za rejestrację!",
    text: "Wysłaliśmy link aktywacyjny na adres e-mail, który podałeś podczas procesu rejestracji.",
    email: "Adres email",
    resendModalTitle: "Wyślij ponownie link aktywacyjny",
    resendModalText:
      "Podaj adres e-mail i kliknij przycisk 'Wyślij', a my wyślemy Ci nowy link aktywacyjny.",
    activateAccount: "Twoje konto wymaga aktywacji",
    didNotReceive: "Nie otrzymałeś emaila?",
    codeSent: "Email z linkiem aktywacyjnym został wysłany.",
    sendAgain: "Wyślij ponownie",
    backToHomePage: "Wróć na stronę główną",
    desclaimer: {
      main: "W przypadku gdy nie dostałeś emaila z linkiem aktywacyjnym:",
      1: {
        main: "Sprawdź folder Spam",
        description:
          "Czasami wiadomości aktywacyjne mogą zostać błędnie odfiltrowane do folderu spamowego lub niechcianego. Prosimy sprawdzić te foldery, jeśli w ciągu kilku minut nie otrzymasz wiadomości e-mail.",
      },
      2: {
        main: "Poczekaj kilka minut",
        description:
          "Okazjonalnie dostarczenie wiadomości e-mail może być opóźnione z powodu przeciążenia sieciowego lub innych czynników. Prosimy odczekać kilka minut przed ponownym żądaniem nowego kodu aktywacyjnego.",
      },
      3: {
        main: "Skontaktuje się",
        description:
          "Jeśli sprawdziłeś swój folder spamu i odczekałeś odpowiednią ilość czasu, a mimo to nie otrzymałeś kodu aktywacyjnego, skontaktuj się z naszym zespołem wsparcia w celu uzyskania pomocy.",
      },
    },
    accountActivated: "Konto zostało aktywowane. Zaloguj sie",
  },
  ProfileMenu: {
    logout: "Wyloguj",
    myAccount: "Moje konto",
    help: "Pomoc",
  },
  Drawer: {
    hello: "Witaj",
    createNewList: "Dodaj listę",
    myLists: "Twoje listy",
    noLists: "Nie masz żadnych list",
  },
  NewListForm: {
    header: "Nowa Lista",
    form: {
      name: "Nazwa",
      description: "Opis",
      submitButton: "Stwórz",
      cancelButton: "Wróć",
    },
  },
  GiftList: {
    giftsProposition: "Masz propozycje {{count}} prezentów",
    allowGiftProposition: "Pozwól gościom na dodawać propozycje prezentów",
    allowGiftPropositionInfo:
      "Pozwól znajomym na dodawanie prezentów. Będziesz mógł zaakceptować lub odrzucić ich propozycje.",
    deleteButton: "Usuń",
    deleteConfirmation: "Potwierdź usunięcie",
    deleteConfirmationMessage:
      "Czy na pewno chcesz usunąć wybraną listę prezentów? <br/>Tej operacji nie można cofnąć.",
    changeButton: "Zmień",
    ready: "Lista gotowa",
    copied: "Skopiowano",
    copy: "Skopiuj link",
    readyQuestion: "Lista gotowa?",
    share: "Udostępnij gościom",
    goToList: "Podejrzyj listę",
    gifts: {
      title: "Prezenty:",
      noData: "Brak danych",
      createNewGift: "Dodaj Prezent",
      ordered: "Zarezerwowany",
    },
    generateLinkConfirmationMessage:
      "Wygenerujesz link do swojej listy. Udostępnij go znajomym, aby mogli zacząć rezerwować prezenty. <br/><br/>Nadal możesz edytować i dodawać nowe prezenty. <br/>Edycja i usuwanie będą zablokowane jedynie dla zarezerwowanych prezentów.",
  },
  NewGiftForm: {
    titleNew: "Dodaj prezent",
    form: {
      name: "Nazwa",
      description: "Opis",
      priceRange: "Zakres cenowy",
      buyLink: "Link do sklepu",
      imageLink: "Link do zdjęcia",
      createButton: "Stwórz",
      saveButton: "Zapisz",
      cancelButton: "Anuluj",
      deleteButton: "Usuń",
      deleteConfirmation: "Czy jesteś pewien?",
    },
    deleteConfirmation: "Potwierdź usunięcie",
    deleteConfirmationMessage:
      "Czy na pewno chcesz usunąć wybrany prezent? <br/>Tej operacji nie można cofnąć.",
  },
  ChangeList: {
    name: "Nazwa",
    description: "Opis",
    cancelButton: "Cofnij",
    submitButton: "Zapisz",
  },
  InviteeList: {
    showReserved: "Pokaż tylko niezarezerwowane prezenty",
    addGift: "Zaproponuj prezent",
    giftPropositionSentToast: "Propozycja prezentu została wysłana",
    giftReservedToast: "Prezent zarezerwowany",
    addGiftTooltip:
      "Twoja propozycja zostanie dodana do listy po zaakceptowaniu przez właściciela listy.",
    reserveButton: "Zarezerwuj",
    reserveConfirmation: "Potwierdź rezerwację",
    reserveConfirmationMessage:
      "Prezent zostanie oznaczony jako zarezerwowany. <br/>Czy na pewno chcesz kontynuować? Tej operacji nie można cofnąć.",
  },
  warnings: {
    fieldRequired: "To pole jest wymagane",
    maxLength: "Tekst jest za długi. Maksymalna liczba znaków to: ",
  },
  MyGiftsTable: {
    noGifts: "Brak prezentów",
    shop: "Sklep",
    reserved: "Zarezerwowany",
    available: "Dostępny",
    approveConfirmation: "Zatwierdź propozycję prezentu",
    approveConfirmationMessage:
      "Po zatwierdzeniu ten prezent będzie dostępny dla Twoich znajomych do zarezerwowania.<br/>Czy na pewno chcesz go zatwierdzić?",
    columns: {
      giftName: "Prezent",
      price: "Cena",
      store: "Sklep",
      image: "Zdjęcie",
      reserved: "Zarezerwowany?",
      approve: "Zatwierdź",
    },
  },
  InviteeGiftsTable: {
    shop: "Sklep",
    reserved: "Zarezerwowany",
    columns: {
      giftName: "Prezent",
      price: "Cena",
      store: "Sklep",
      image: "Zdjęcie",
      reserve: "Zarezerwuj",
    },
  },
  InviteeGiftsCard: {
    shop: "Sklep",
    reserve: "Zarezerwuj",
    reserved: "Zarezerwowany",
  },
  MyAccount: {
    myAccount: "Moje konto",
    passwordChange: {
      title: "Zmień hasło",
      oldPassword: "Stare hasło",
      newPassword: "Nowe hasło",
      confirmNewPassword: "Potwierdź nowe hasło",
      success: "Hasło zostało zmienione",
      confirmationTitle: "Potwierdzenie zmiany hasła",
      confirmationMessage: "Czy na pewno chcesz zmienić hasło?",
    },
    accountDetails: {
      title: "Dane konta",
      email: "E-mail",
      created: "Konto stworzone",
      lists: "Liczba stworzonych list",
      gifts: "Liczba stworzonych prezentów",
    },
    termsAndRegulations: regulamin_pl,
    deleteAccount: {
      title: "Usuń konto",
      confirmationTitle: "Potwierdź usunięcie konta",
      confirmationText: "Potwierdzam",
      checkbox: "Potwierdzam, że chcę usunąć konto",
      text1:
        "Potwierdź swoją tożsamość, podając hasło, aby kontynuować usunięcie konta.",
      text2:
        "Po usunięciu konta: <4><0>Wszystkie Twoje dane i zapisane informacje zostaną trwale usunięte.</0><1>Stracisz dostęp do swojego profilu i historii aktywności.</1><2>Ta operacja jest nieodwracalna. <3>Nie będzie możliwości odzyskania danych.</3></2><4/>",
    },
  },
  Help: {
    title: "Pomoc",
    header: "Masz pytania lub potrzebujesz pomocy?",
    text: "Jeśli napotkałeś problem lub masz jakiekolwiek pytania, skontaktuj się z nami za pomocą formularza kontaktowego dostępnego na stronie. Postaramy się odpowiedzieć na Twoje zgłoszenie tak szybko, jak to możliwe!",
    label: "Treść wiadomości",
    messageSent: "Wiadomość została wysłana",
    send: "Wyślij wiadomość",
  },
  Cookies: {
    title: "Używamy plików cookies",
    text: "Nasza aplikacja używa wyłącznie niezbędnych plików cookies w celu poprawnego działania aplikacji. Kontynuując korzystanie z aplikacji, zgadzasz się na ich użycie.",
    acceptAndClose: "Akceptuj i zamknij",
  },
  Common: {
    invalidEmail: "Nieprawidłowy adres e-mail",
    no: "Nie",
    yes: "Tak",
    accept: "Akceptuj",
    cancel: "Anuluj",
    confirm: "Zatwierdź",
    ok: "Ok",
    back: "Cofnij",
    attention: "Uwaga",
    send: "Wyślij",
    edit: "Zmień",
  },
  DjangoErrors: {
    invalid: "Coś poszło nie tak.",
    "invalid-link": "Nieprawidłowy link aktywacyjny.",
    "invalid-user": "Nieprawidłowy użytkownik.",
    "email-required": "Email jest wymagany.",
    "wait-for-another-gift-proposition":
      "Musisz poczekać 1 minutę przed wysłaniem kolejnej propozycji prezentu.",
    "password-needs-to-be-at-least-8-chars-long-contain-a-capital-letter-and-a-numer":
      "Hasło musi zawierać co najmniej 8 znaków, dużą literę i cyfrę.",
    "confirmation-password-and-password-differs":
      "Hasło i potwierdzenie hasła są różne.",
    "old-password-is-incorrect": "Stare hasło jest nieprawidłowe.",
    "password-is-incorrect": "Hasło jest nieprawidłowe.",
    "invalid-email": "Nieprawidłowy adres e-mail",
    "A user with that username already exists.":
      "Użytkownik o takim adresie e-mail już istnieje.",
    "wait-for-another-help-message":
      "Możesz wysłać tylko jedną wiadomość pomocy na minutę",
  },
};

export default translation;
