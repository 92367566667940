import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CssTextField from "../components/CssTextField";
import { useState } from "react";
import { useAccount } from "../hooks";

const ResendActivationLinkModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { t: _t } = useTranslation(undefined, { keyPrefix: "DjangoErrors" });

  const { sendAgain } = useAccount();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: null });

  const validateEmail = (value) => {
    if (!value) {
      setErrors({ ...errors, email: "Required." });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      setErrors({ ...errors, email: t("Common.invalidEmail") });
    } else setErrors({ ...errors, email: null });
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    validateEmail(value);
    setEmail(value);
  };

  const _onClose = () => {
    setEmail("");
    setErrors({ email: null });
    onClose();
  };

  const onResend = (email) =>
    sendAgain
      .mutateAsync(email)
      .then(_onClose)
      .catch((err) => setErrors(err.response.data));

  const onKeyDown = (e) => e.key === "Enter" && onResend(email);

  return (
    <Dialog open={open} onClose={_onClose}>
      <DialogTitle>{t("Activate.resendModalTitle")}</DialogTitle>
      <DialogContent sx={{ minWidth: "400px" }}>
        <DialogContentText>{t("Activate.resendModalText")}</DialogContentText>
        <br />
        <CssTextField
          focusColor="black"
          fullWidth
          label={t("Activate.email")}
          placeholder={t("Activate.email")}
          value={email}
          onChange={handleEmailChange}
          type="email"
          error={!!errors.email}
          helperText={_t(errors.email, "") || errors.email}
          onKeyDown={onKeyDown}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={_onClose}>
          {t("Common.cancel")}
        </Button>
        <Button onClick={() => onResend(email)} autoFocus>
          {t("Common.send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResendActivationLinkModal;
