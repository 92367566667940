import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const InnerContainer = styled("div")`
  margin: auto;
  width: 500px;
`;

const PasswordRecoverPage = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <InnerContainer>
      <center>
        <Typography variant={"h4"} component={"div"}>
          {t("Recover.success")}
        </Typography>
        <br />
        <Typography>
          Nowe hasło zostało wysłane na podany adres e-mail.
        </Typography>
        <br />
        <Button variant="contained" onClick={() => navigate("/sign-in")}>
          Zaloguj sie
        </Button>
      </center>
    </InnerContainer>
  );
};

export default PasswordRecoverPage;
