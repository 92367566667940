import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMobile } from "../components/MobileWrapper";

const CookieToast = ({ closeToast }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "Cookies" });
  const handleClick = () => {
    localStorage.setItem("gifteeme-cookies-accepted", "true");
    closeToast();
  };
  return (
    <Box>
      <h4 style={{ marginTop: "10px" }}>{t("title")}</h4>
      <p>{t("text")}</p>
      <Button onClick={handleClick}>{t("acceptAndClose")}</Button>
    </Box>
  );
};

export default function ConsentProvider() {
  const { isMobile } = useMobile();
  useEffect(() => {
    const sx = isMobile ? {} : { width: "700px", marginLeft: "-400px" };
    if (localStorage.getItem("gifteeme-cookies-accepted") !== "true")
      setTimeout(
        () =>
          toast(({ closeToast }) => <CookieToast closeToast={closeToast} />, {
            position: toast.POSITION.BOTTOM_RIGHT, // Custom position
            autoClose: false, // Don't auto-close
            closeOnClick: false, // Ignore close on click
            draggable: false, // Prevent dragging
            closeButton: false, // Hide close button
            style: sx,
          }),
        1500
      );
  }, [isMobile]);
  return <div />;
}
