import {
  AUTH_FAIL,
  AUTH_SUCCESS,
  LOGOUT,
  SET_SELF_USER,
} from "../actions/auth";

export const authReducer = (
  state = {
    token: undefined,
    user: undefined,
    timestamp: undefined,
    message: "",
  },
  action
) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        timestamp: new Date().getTime() / 1000,
      };
    case LOGOUT:
      return {
        ...state,
        token: undefined,
        user: undefined,
        timestamp: undefined,
        message: "",
      };
    case AUTH_FAIL:
      return {
        ...state,
        token: undefined,
        user: undefined,
        timestamp: undefined,
        message: action.data.message,
      };
    case SET_SELF_USER:
      return {
        ...state,
        user: action?.data?.user,
      };

    default:
      return state;
  }
};
