import { Button, Tooltip, TextField, Typography, Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import StyledFlex from "../components/StyledFlex";

const InviteLink = ({ inviteLink }) => {
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}/list/${inviteLink}`
    );
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
  };
  return (
    <Box>
      <Typography>{t("GiftList.ready")}</Typography>
      <br />
      <StyledFlex>
        <TextField
          fullWidth
          value={`${process.env.REACT_APP_URL}/list/${inviteLink}`}
          size="small"
          inputProps={{ style: { cursor: "pointer" } }}
          focused={false}
          onClick={() => window.open(`/list/${inviteLink}`)}
          label={t("GiftList.ready")}
        />
        &nbsp;&nbsp;
        <Tooltip
          title={t("GiftList.copied")}
          disableHoverListener
          open={tooltipOpen}
        >
          <Button
            sx={{ minWidth: "140px" }}
            variant="outlined"
            color="black"
            size="small"
            onClick={handleCopyClick}
          >
            {t("GiftList.copy")}
          </Button>
        </Tooltip>
      </StyledFlex>
    </Box>
  );
};

export default InviteLink;
