import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "./reducers/auth";
import { modalsReducer } from "./reducers/modals";
import { loadingReducer } from "./reducers/loading";

const store = createStore(
  combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    modals: modalsReducer,
  }),
  applyMiddleware(thunk)
);

export default store;
