import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import gifteemeLogo from "../../shared/gifteeme-logo.png";
import "./style.css";

const Backdrop = styled("div")`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const Loading = (props) => {
  return <img alt="loading" className="spinning" src={gifteemeLogo} />;
};

export const LoadingWithBackdrop = (props) => {
  return (
    <Backdrop>
      <Loading />
    </Backdrop>
  );
};

export default Loading;

const mapStateToProps = (state) => ({ isLoading: state.loading.status });

export const _LoadingProvider = (props) =>
  props.isLoading ? <LoadingWithBackdrop /> : null;

export const LoadingProvider = connect(mapStateToProps)(_LoadingProvider);
