import { MODAL_TIMEOUT } from "../../shared/settings";

export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";
export const PRE_CLOSE_LOGIN_MODAL = "PRE_CLOSE_LOGIN_MODAL";

export const OPEN_GIFT_MODAL = "OPEN_GIFT_MODAL";
export const PRE_CLOSE_GIFT_MODAL = "PRE_CLOSE_GIFT_MODAL";
export const CLOSE_GIFT_MODAL = "CLOSE_GIFT_MODAL";

export const openLoginModal = () => (dispatch) =>
  dispatch({ type: OPEN_LOGIN_MODAL });

export const closeLoginModal = () => (dispatch) => {
  dispatch({ type: PRE_CLOSE_LOGIN_MODAL });
  setTimeout(() => dispatch({ type: CLOSE_LOGIN_MODAL }), MODAL_TIMEOUT);
};

export const openGiftModal = (data) => (dispatch) =>
  dispatch({ type: OPEN_GIFT_MODAL, data: data });

export const closeGiftModal = () => (dispatch) => {
  dispatch({ type: PRE_CLOSE_GIFT_MODAL });
  setTimeout(() => dispatch({ type: CLOSE_GIFT_MODAL }), MODAL_TIMEOUT);
};
