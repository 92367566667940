import { connect } from "react-redux";
import { Box, Typography } from "@mui/material";

import MyLists from "./MyLists";
import { useTranslation } from "react-i18next";

const Drawer = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: 300,
        height: "100vh",
        color: "black",
        backgroundColor: "#fff",
        boxShadow: "1px 0 1px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Typography sx={{ p: 2 }}>
        {t("Drawer.hello")} {props.user.full_name}!
      </Typography>
      <br />
      <MyLists />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Drawer);
