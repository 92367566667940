import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { openLoginModal } from "../store/actions/modals";
import { connect } from "react-redux";
import gifteemeLogo from "../shared/gifteeme-logo.png";
import { useMobile } from "../components/MobileWrapper";

import MyLists from "../AppToolbar/MyLists";

const Home = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  if (props.user && isMobile) return <MyLists />;
  return (
    <Box textAlign="center">
      <img alt="" src={gifteemeLogo} />
      <br />
      <br />
      <Typography>{t("Home.welcome")}</Typography>
      {!props.user && <Typography>{t("Home.signIn")}</Typography>}

      {!props.user && (
        <Box>
          <br />
          <br />
          <Button variant="contained" onClick={() => props.openLoginModal()}>
            {t("Login.sign_in")}
          </Button>
          <br />
          <br />
          <Button
            onClick={() => navigate("/create-new-account")}
            sx={{ mt: 1 }}
          >
            {t("Login.create_account")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });
const mapDispatchToProps = { openLoginModal };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
