import { Route, Routes } from "react-router";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";

import AppToolbar from "./AppToolbar";
import Drawer from "./AppToolbar/Drawer";
import NewGiftList from "./NewGiftList";
import Home from "./Home";
import Container from "./components/Container";
import List from "./List";
import ChangeList from "./ChangeList";
import { useMobile } from "./components/MobileWrapper";
import MyAccount from "./MyAccount";
import Help from "./Help";

const AppContainer = styled("div")``;

function App(props) {
  const { isMobile } = useMobile();
  const drawerMarginSx = isMobile ? { ml: "0px" } : { ml: "300px" };
  const containerMarginSx = isMobile ? { mt: "80px" } : { mt: "150px" };

  if (!props.user) return null;

  return (
    <AppContainer sx={drawerMarginSx}>
      {!isMobile && <Drawer />}
      <AppToolbar fullWidth={isMobile} />
      <Container sx={containerMarginSx}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/lists/new-list/" element={<NewGiftList />} />
          <Route exact path="/lists/:listId/" element={<List />} />
          <Route exact path="/lists/:listId/change/" element={<ChangeList />} />
          <Route exact path="/my-account/" element={<MyAccount />} />
          <Route exact path="/help/" element={<Help />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Container>
    </AppContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(App);
