import { Trans, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { connect } from "react-redux";

import { useMyGifts, useMyLists } from "../hooks";
import { openGiftModal } from "../store/actions/modals";
import InviteLink from "./InviteLink";
import ReadyQuestion from "./ReadyQuestion";
import Panel from "../components/Panel";
import axiosClient from "../api-client";
import MyGiftsTable from "./MyGiftsTable";
import StyledFlex from "../components/StyledFlex";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import ConfirmationModal from "../components/ConfirmationModal";
import AllowGiftPropositionSwitch from "./AllowGiftPropositionSwitch";
import PropositionGifts from "./PropositionGifts";
import MobileWrapper, { useMobile } from "../components/MobileWrapper";
import GiftCards from "./GiftCards";

const Gifts = ({ listId, ...props }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: lists } = useMyLists();
  const list = lists?.find((list) => String(list.id) === listId);
  const { data: myGifts } = useMyGifts();
  const gifts = myGifts.filter((gift) => String(gift.list) === listId);

  const { isMobile } = useMobile();

  const [generateLinkConfimationOpen, setGenerateLinkConfimationOpen] =
    useState(false);

  const reserve = () =>
    axiosClient
      .post(`/api/v1/gifts/gift-lists/${listId}/generate-invite-link/`)
      .then(() => {
        queryClient.invalidateQueries(["my-lists"]);
        setGenerateLinkConfimationOpen(false);
      });

  const mobileSx = isMobile ? { m: "0px -20px" } : {};
  
  if (!list) return null;

  return (
    <>
      {!!gifts?.length && (
        <Panel sx={mobileSx}>
          {!!list?.invite_link && <InviteLink inviteLink={list?.invite_link} />}
          {!list?.invite_link && !!gifts.length && (
            <ReadyQuestion
              handleGenerateLinkClick={() =>
                setGenerateLinkConfimationOpen(true)
              }
            />
          )}
          <br />
          <AllowGiftPropositionSwitch key={list.id} list={list} />
        </Panel>
      )}
      <StyledFlex>
        <Box>
          <h2>{t("GiftList.gifts.title")}</h2>
        </Box>
        <Button
          onClick={() => props.openGiftModal({ list_id: listId })}
          startIcon="+"
          variant="contained"
          size="small"
        >
          {t("NewGiftForm.titleNew")}
        </Button>
      </StyledFlex>

      <MobileWrapper
        component={
          <Box mb="20px">
            <PropositionGifts />
          </Box>
        }
      >
        <Panel sx={{ overflowY: "auto", mb: "20px" }}>
          <PropositionGifts />
        </Panel>
      </MobileWrapper>

      <MobileWrapper component={<GiftCards />}>
        <Panel sx={{ overflowY: "auto" }}>
          <MyGiftsTable />
        </Panel>
      </MobileWrapper>
      <Box height="200px" />
      <ConfirmationModal
        open={generateLinkConfimationOpen}
        onClose={() => setGenerateLinkConfimationOpen(false)}
        title={<Trans i18nKey="Common.attention" />}
        message={<Trans i18nKey="GiftList.generateLinkConfirmationMessage" />}
        onConfirm={reserve}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  openGiftModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gifts);
