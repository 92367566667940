import axiosClient from "../../api-client";


export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_SELF_USER = "SET_SELF_USER";

export const reduxLogin = (token, user) => (dispatch) =>
  dispatch({
    type: AUTH_SUCCESS,
    data: {
      token: token,
      user: user,
      message: "login-success",
    },
  });

export const reduxLogout = () => ({ type: LOGOUT });

export const setSelfUser = (user) => (dispatch) =>
  dispatch({
    // the related reducer action handler is in auth.js
    type: SET_SELF_USER,
    data: {
      user: user,
    },
  });

export const getSelfUser = () => (dispatch) => {
  axiosClient.get(`/api/v1/users/self/`).then((response) => {
    dispatch(setSelfUser(response?.data));
  });
};
