import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMobile } from "../components/MobileWrapper";
import PasswordField from "../components/PasswordField";
import ConfirmationModal from "../components/ConfirmationModal";
import { useState } from "react";
import { useAccount } from "../hooks";

export default function PasswordChangeDialog(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const { t: _t } = useTranslation(undefined, { keyPrefix: "DjangoErrors" });
  const { isMobile } = useMobile();
  const { changePassword } = useAccount();

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const _onClose = () => {
    changePassword.reset();
    setOldPassword(null);
    setNewPassword(null);
    setConfirmNewPassword(null);
    onClose();
  };
  const errors = changePassword.error?.response?.data || {};

  const handleChangePassword = () =>
    changePassword
      .mutateAsync({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      })
      .then(_onClose);

  return (
    <Dialog open={open} onClose={_onClose} fullScreen={isMobile}>
      <DialogTitle>{t("MyAccount.passwordChange.title")}</DialogTitle>
      <DialogContent>
        <PasswordField
          fullWidth
          sx={{ m: "5px 0px" }}
          label={t("MyAccount.passwordChange.oldPassword")}
          value={oldPassword ?? ""}
          onChange={(e) => setOldPassword(e.target.value)}
          error={errors.old_password}
          helperText={_t(errors.old_password, "")}
          onKeyPress={(e) => e.key === "Enter" && setConfirmationOpen(true)}
        />
        <PasswordField
          fullWidth
          sx={{ m: "5px 0px" }}
          label={t("MyAccount.passwordChange.newPassword")}
          value={newPassword ?? ""}
          onChange={(e) => setNewPassword(e.target.value)}
          error={errors.new_password}
          helperText={_t(errors.new_password, "")}
          onKeyPress={(e) => e.key === "Enter" && setConfirmationOpen(true)}
        />
        <PasswordField
          fullWidth
          sx={{ m: "5px 0px" }}
          label={t("MyAccount.passwordChange.confirmNewPassword")}
          value={confirmNewPassword ?? ""}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          error={errors.confirm_new_password}
          helperText={_t(errors.confirm_new_password, "")}
          onKeyPress={(e) => e.key === "Enter" && setConfirmationOpen(true)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color="black">
          {t("Common.cancel")}
        </Button>
        <Button onClick={() => setConfirmationOpen(true)}>
          {t("MyAccount.passwordChange.title")}
        </Button>
      </DialogActions>
      <ConfirmationModal
        open={!!confirmationOpen}
        title={t("MyAccount.passwordChange.confirmationTitle")}
        message={t("MyAccount.passwordChange.confirmationMessage")}
        onClose={() => setConfirmationOpen(null)}
        onConfirm={() => {
          setConfirmationOpen(false);
          handleChangePassword();
        }}
      />
    </Dialog>
  );
}
