import { Dialog } from "@mui/material";
import { connect } from "react-redux";
import { closeGiftModal } from "../../store/actions/modals";

import GiftForm from "./GiftForm";
import { useMyGifts } from "../../hooks";
import { useMobile } from "../../components/MobileWrapper";

const GiftModal = (props) => {
  const { isMobile } = useMobile();
  const { _create, _update, _delete } = useMyGifts();

  const handleDelete = () =>
    _delete.mutateAsync(props.data?.id).then(props.closeGiftModal);

  const handleSubmit = (data) =>
    !props.data?.id
      ? _create
          .mutateAsync({
            ...data,
            list: props.data.list_id,
            price_range: data.priceRange,
            buy_link: data.buyLink,
            image_link: data.imageLink,
          })
          .then(props.closeGiftModal)
      : _update
          .mutateAsync({
            ...data,
            id: props.data.id,
            price_range: data.priceRange,
            buy_link: data.buyLink,
            image_link: data.imageLink,
          })
          .then(props.closeGiftModal);

  return (
    <Dialog
      open={props.open}
      onClose={props.closeGiftModal}
      fullWidth
      fullScreen={isMobile}
      maxWidth={props?.data?.image_link ? "lg" : "sm"}
    >
      <GiftForm
        gift={props.data}
        onSubmit={handleSubmit}
        onCancel={props.closeGiftModal}
        onDelete={handleDelete}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  open: state.modals.gift.open,
  data: state.modals.gift.data,
});

const mapDispatchToProps = {
  closeGiftModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftModal);
