import { Route, Routes } from "react-router";

import InviteeList from "./InviteeList";
import RegistrationPage from "./RegistrationPage";
import ActivationPage from "./ActivationPage";
import LoginPage from "./LoginPage";
import PasswordRecoverPage from "./PasswordRecoverPage";
import PasswordRecoverSuccessPage from "./PasswordRecoverSuccessPage";
import { Container } from "@mui/material";
import AppToolbar from "./AppToolbar";
import Home from "./Home";
import { TOKEN_KEY } from "./conf";
import Activation from "./ActivationPage/Activation";

const _ = (component) => (
  <Container sx={{ mt: "150px" }}>
    <AppToolbar />
    {component}
  </Container>
);

const AnonymousRequestProvider = (props) => {
  const token = localStorage.getItem(TOKEN_KEY);
  return (
    <Routes>
      <Route exect path="/list/:inviteLink" element={<InviteeList />} />

      <Route exact path="/sign-in" element={_(<LoginPage />)} />
      <Route path="/create-new-account" element={_(<RegistrationPage />)} />
      <Route path="/activation-required" element={_(<ActivationPage />)} />
      <Route path="/activate/:key" element={_(<Activation />)} />
      <Route path="/password-recovery" element={_(<PasswordRecoverPage />)} />
      <Route
        path="/recover-success"
        element={_(<PasswordRecoverSuccessPage />)}
      />

      {!!token && <Route path="*" element={props.children} />}
      {!token && <Route path="*" element={_(<Home />)} />}
      
    </Routes>
  );
};

export default AnonymousRequestProvider;
