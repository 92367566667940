import LoginModal from "./LoginModal";
import GiftModal from "./GiftModal";
import { connect } from "react-redux";

const ModalProvider = (props) => {
  return (
    <>
      <LoginModal />
      {props.user && <GiftModal />}
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps)( ModalProvider);
