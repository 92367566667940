import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { setLoadingStatus } from "../store/actions/loading";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAccount } from "../hooks";
import { Box, Typography } from "@mui/material";

const Activation = (props) => {
  const { setLoadingStatus } = props;
  const { t } = useTranslation();
  const { key } = useParams();
  const [activated, setAcitvated] = useState(false);
  const { activate } = useAccount();

  useEffect(() => {
    setLoadingStatus(true);
    activate.mutateAsync(key).finally(() => {
      setLoadingStatus(false);
      setAcitvated(true);
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    });
    // eslint-disable-next-line
  }, []);
  if (!activated) return null;

  return (
    <Box textAlign="center">
      <Typography>{t("Activate.accountActivated")}</Typography>
    </Box>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { setLoadingStatus };

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
