import {
  IconButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@mui/material";

export default function Item(props) {
  return (
    <MenuItem onClick={props.onClick}>
      <ListItemAvatar>
        <IconButton >{props.icon}</IconButton>
      </ListItemAvatar>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  );
}
