import { connect } from "react-redux";

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import gifteemeLogo from "../shared/gifteeme-logo.png";
import { useTranslation } from "react-i18next";
import { useMyLists } from "../hooks";

function extractNumberFromPath(inputString) {
  // Check if the inputString contains "lists"
  if (inputString.includes("lists/")) {
    // Use regular expression to match the number following "lists/"
    const match = inputString.match(/lists\/(\d+)/);

    // Check if a match was found
    if (match) {
      // Extract and return the matched number
      return parseInt(match[1]);
    }
  }

  // If "lists" is not found or no number is found, return undefined
  return undefined;
}

const MyLists = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const listId = extractNumberFromPath(window.location.href);
  const { data } = useMyLists();

  const myGiftLists = data || [];

  return (
    <List sx={{ width: "100%" }}>
      <ListSubheader sx={{bgcolor:"inherit"}}>{t("Drawer.myLists")}:</ListSubheader>

      {!myGiftLists.length && (
        <Typography sx={{ w: "100%", textAlign: "center", opacity: 0.7 }}>
          {t("Drawer.noLists")}
        </Typography>
      )}

      <Box overflow="auto" maxHeight="calc(100vh - 220px)">
        {myGiftLists.map((giftList) => (
          <ListItem
            key={giftList?.id}
            button
            onClick={() => navigate(`/lists/${giftList?.id}/`)}
            selected={String(listId) === String(giftList.id)}
          >
            <ListItemIcon>
              <img alt="" width={35} src={gifteemeLogo} />
            </ListItemIcon>
            <ListItemText>{giftList?.name}</ListItemText>
          </ListItem>
        ))}
      </Box>
      <br />

      <Button
        sx={{ width: "90%", ml: "5%" }}
        variant="outlined"
        onClick={() => navigate("/lists/new-list/")}
      >
        {t("Drawer.createNewList")}
      </Button>
    </List>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MyLists);
