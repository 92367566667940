import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./en";
import translationPl from "./pl";

const setup = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: translationEn },
      pl: { translation: translationPl },
    },
    lng: "pl",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });
};

export default setup;
