import { Dialog } from "@mui/material";
import { connect } from "react-redux";
import { closeLoginModal } from "../../store/actions/modals";
import Login from "./Login";
import { useMobile } from "../../components/MobileWrapper";

const LoginModal = (props) => {
  const { isMobile } = useMobile();
  return (
    <Dialog open={props.open} onClose={props.closeLoginModal} fullScreen={isMobile}>
      <Login onClose={props.closeLoginModal}/>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  open: state.modals.login.open,
});

const mapDispatchToProps = {
  closeLoginModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
