import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { reduxLogout } from "../store/actions/auth";
import { IoPerson } from "react-icons/io5";
import { MdAccountCircle, MdHelp } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { useMutation } from "react-query";
import axiosClient from "../api-client";
import { TOKEN_KEY } from "../conf";
import MobileWrapper from "../components/MobileWrapper";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)`
  color: #fff;
  text-transform: none;
`;

const ProfileMenu = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const { mutate } = useMutation(TOKEN_KEY, () => {
    axiosClient.post(`/api/v1/auth/logout/`).then(() => {
      reduxLogout();
      localStorage.removeItem(TOKEN_KEY);
      window.location.reload();
      return null;
    });
  });

  const handleMyAccountClick = () => {
    navigate("/my-account/");
    handleClose();
  };

  const handleHelpClick = () => {
    navigate("/help/");
    handleClose();
  };

  return (
    <>
      <MobileWrapper
        component={
          <IconButton color="secondary" onClick={handleClick}>
            <MdAccountCircle />
          </IconButton>
        }
      >
        <StyledButton
          startIcon={<MdAccountCircle />}
          variant="outlined"
          color="secondary"
          onClick={handleClick}
          sx={{ maxWidth: "150px" }}
        >
          <Typography noWrap>{props.user.full_name}</Typography>
        </StyledButton>
      </MobileWrapper>
      <Menu
        anchorEl={anchorEl}
        style={{ marginTop: 0, paddingTop: 0 }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: "10px 40px", textAlign: "center" }}>
          <Typography>{props.user.full_name}</Typography>
          {props.user.full_name !== props.user.email && (
            <small>{props.user.email}</small>
          )}
        </div>
        <Divider sx={{ mb: 1.5 }} />
        <MenuItem onClick={handleMyAccountClick}>
          <ListItemIcon>
            <IoPerson />
          </ListItemIcon>
          {t("ProfileMenu.myAccount")}
        </MenuItem>
        <MenuItem onClick={handleHelpClick}>
          <ListItemIcon>
            <MdHelp />
          </ListItemIcon>
          {t("ProfileMenu.help")}
        </MenuItem>
        <MenuItem onClick={mutate}>
          <ListItemIcon>
            <FiLogOut />
          </ListItemIcon>
          {t("ProfileMenu.logout")}
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });
const mapDispatchToProps = { reduxLogout };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
