import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledDisclaimer = styled("div")`
  margin-top: 20px;
  opacity: 0.5;
`;

const ActivationDesclaimer = () => {
  const { t } = useTranslation();
  return (
    <StyledDisclaimer>
      <Typography>{t("Activate.desclaimer.main")}</Typography>
      <ul>
        <li>
          <Typography>{t("Activate.desclaimer.1.main")}</Typography>
        </li>
        <Typography>{t("Activate.desclaimer.1.description")}</Typography>
        <br />
        <li>
          <Typography>{t("Activate.desclaimer.2.main")}</Typography>
        </li>
        <Typography>{t("Activate.desclaimer.2.description")}</Typography>
        <br />
        <li>
          <Typography>{t("Activate.desclaimer.3.main")}</Typography>
        </li>
        <Typography>{t("Activate.desclaimer.3.description")}</Typography>
      </ul>
    </StyledDisclaimer>
  );
};

export default ActivationDesclaimer;
