import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { PhotoProvider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import store from "./store";
import App from "./App";
import InitialRequest from "./InitialRequest";
import AnonymousRequestProvider from "./AnonymousRequestProvider";
import AuthorizationProvider from "./AuthorizationProvider";
import { primary, secondary } from "./shared/colors";
import { LoadingProvider } from "./components/Loading";
import ModalProvider from "./modals";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import ConsentProvider from "./Providers/ConsentProvider";

import "./fonts/Fair_Prosper.ttf";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import langSetup from "./lang/setup.js";

const theme = createTheme({
  palette: {
    primary,
    secondary,
    black: { main: "#000", contrastText: "#fff" },
    green: { main: "green", contrastText: "#fff" },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
  },
});

langSetup();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // Disable automatic retries on query failures
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <PhotoProvider>
              <ConsentProvider />
              <LoadingProvider />
              <ModalProvider />
              <AnonymousRequestProvider>
                <AuthorizationProvider>
                  <>
                    <InitialRequest />
                    <App />
                  </>
                </AuthorizationProvider>
              </AnonymousRequestProvider>
            </PhotoProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals(console.log);
