import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useState } from "react";
import MobileWrapper from "../../components/MobileWrapper";

const priceRanges = [
  { value: "10-20", label: "10-20 PLN" },
  { value: "20-30", label: "20-30 PLN" },
  { value: "30-40", label: "30-40 PLN" },
  { value: "40-60", label: "40-60 PLN" },
  { value: "60-80", label: "60-80 PLN" },
  { value: "80-100", label: "80-100 PLN" },
  { value: "100-150", label: "100-150 PLN" },
  { value: "150-200", label: "150-200 PLN" },
  { value: "200-300", label: "200-300 PLN" },
  { value: "300-400", label: "300-400 PLN" },
  { value: "400-500", label: "400-500 PLN" },
  { value: "500-1000", label: "500-1000 PLN" },
  { value: "1000-2000", label: "1000-2000 PLN" },
  { value: "2000+", label: "2000+ PLN" },
];

const GiftForm = ({ gift, onSubmit, onDelete, onCancel }) => {
  const { t } = useTranslation();
  const isNew = !gift?.id;

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values?.name) {
      errors.name = t("warnings.fieldRequired");
    } else if (values.name.length > 100) {
      errors.name = t("warnings.maxLength") + "100";
    }
    if (!values?.priceRange) {
      errors.priceRange = t("warnings.fieldRequired");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: gift?.name || "",
      description: gift?.description || "",
      priceRange: gift?.price_range || "",
      buyLink: gift?.buy_link || "",
      imageLink: gift?.image_link || "",
    },
    validate,
    onSubmit: onSubmit,
  });

  const title = isNew ? t("NewGiftForm.titleNew") : `Prezent: ${gift.name}`;

  return (
    <>
      <DialogTitle sx={{ p: 3 }}>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <div style={{ display: "flex" }}>
          <form style={{ padding: 10 }} onSubmit={formik.handleSubmit}>
            <TextField
              disabled={gift.reserved}
              fullWidth
              autoFocus
              label={t("NewGiftForm.form.name") + "*"}
              id="name"
              onChange={formik.handleChange}
              value={formik.values?.name}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
              sx={{ mb: "20px" }}
            />

            <TextField
              disabled={gift.reserved}
              multiline
              fullWidth
              label={t("NewGiftForm.form.description")}
              id="description"
              onChange={formik.handleChange}
              value={formik.values?.description}
              error={!!formik.errors.description}
              helperText={formik.errors.description}
              sx={{ mb: "20px" }}
            />

            <FormControl fullWidth sx={{ mb: "20px" }}>
              <InputLabel>{t("NewGiftForm.form.priceRange") + "*"}</InputLabel>
              <Select
                disabled={gift.reserved}
                name="priceRange"
                fullWidth
                label={t("NewGiftForm.form.priceRange") + "*"}
                id="priceRange"
                onChange={formik.handleChange}
                value={formik.values?.priceRange}
                error={!!formik.errors.priceRange}
              >
                {priceRanges.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {!!formik.errors.priceRange && (
                <Typography
                  disabled={gift.reserved}
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    padding: "3px 15px",
                  }}
                >
                  {formik.errors.priceRange}
                </Typography>
              )}
            </FormControl>
            <TextField
              disabled={gift.reserved}
              fullWidth
              label={t("NewGiftForm.form.buyLink")}
              id="buyLink"
              onChange={formik.handleChange}
              value={formik.values?.buyLink}
              error={formik.errors.buyLink}
              helperText={formik.errors.buyLink}
              sx={{ mb: "20px" }}
            />
            <TextField
              disabled={gift.reserved}
              fullWidth
              label={t("NewGiftForm.form.imageLink")}
              id="imageLink"
              onChange={formik.handleChange}
              value={formik.values?.imageLink}
              error={formik.errors.imageLink}
              helperText={formik.errors.imageLink}
            />
          </form>
          <MobileWrapper>
            {gift.image_link && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <img
                  src={gift.image_link}
                  alt="gift"
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    width: "auto",
                    height: "auto",
                  }}
                />
              </div>
            )}
          </MobileWrapper>
        </div>
      </DialogContent>
      <Divider />
      {gift.reserved && (
        <DialogContent
          sx={{ textAlign: "center", bgcolor: "#008000", color: "#fff" }}
        >
          {t("GiftList.gifts.ordered")}
        </DialogContent>
      )}
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        {!isNew && (
          <Button
            disabled={gift.reserved}
            onClick={() => setDeleteConfirmationOpen(true)}
          >
            {t("NewGiftForm.form.deleteButton")}
          </Button>
        )}
        <div style={{ flex: "1 0 0" }} />
        <Button variant="outlined" onClick={onCancel}>
          {t("NewGiftForm.form.cancelButton")}
        </Button>
        <Button
          disabled={gift.reserved}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          {isNew
            ? t("NewGiftForm.form.createButton")
            : t("NewGiftForm.form.saveButton")}
        </Button>
      </DialogActions>
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={() => {
          onDelete();
          setDeleteConfirmationOpen(false);
        }}
        title={t("NewGiftForm.deleteConfirmation")}
        message={<Trans i18nKey={"NewGiftForm.deleteConfirmationMessage"} />}
        confirmText={t("GiftList.deleteButton")}
      />
    </>
  );
};

export default GiftForm;
