import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

export default function PasswordField(props) {
  const { component, ..._props } = props;
  const [visible, setVisible] = useState(false);
  const Component = component || TextField;
  return (
    <Component
      {..._props}
      type={visible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setVisible((prevState) => !prevState)}>
            {visible ? <MdVisibility /> : <MdVisibilityOff />}
          </IconButton>
        ),
      }}
    />
  );
}
