import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Gifts from "./Gifts";
import { Box } from "@mui/material";
import { useMyLists } from "../hooks";
import StyledFlex from "../components/StyledFlex";

import ActionButtons from "./ActionButtons";
import MobileWrapper from "../components/MobileWrapper";

const StyledName = styled("h2")``;
const StyledDescription = styled("small")``;

const List = () => {
  const { listId } = useParams();
  const { data } = useMyLists();
  const list = data.find((list) => String(list.id) === listId);

  if (!list) return null;

  return (
    <>
      <StyledFlex sx={{ alignItems: "flex-start" }}>
        <Box>
          <StyledName>{list.name}</StyledName>
          <StyledDescription>{list.description}</StyledDescription>
        </Box>
        <MobileWrapper>
          <ActionButtons listId={listId} />
        </MobileWrapper>
      </StyledFlex>
      <MobileWrapper component={<ActionButtons listId={listId} />} />
      <br />
      <Gifts listId={listId} />
    </>
  );
};

export default List;
