import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMobile } from "../components/MobileWrapper";
import StyledFlex from "../components/StyledFlex";

const Item = (props) => {
  const { no } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: "MyAccount.termsAndRegulations",
  });
  const pl = no.split(".").length > 2 ? "20px" : "0px";

  return (
    <StyledFlex
      sx={{
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pb: "5px",
      }}
    >
      <Typography minWidth="50px">{no}</Typography>
      <Typography sx={{ pl }}>{t(no.replaceAll(".", "-"))}</Typography>
    </StyledFlex>
  );
};
export default function TermsAndRegulationsDialog(props) {
  const { open, onClose } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: "MyAccount.termsAndRegulations",
  });
  const { t: _t } = useTranslation();
  const { isMobile } = useMobile();

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" p="10px 0px">
          1. {t(1)}
        </Typography>
        <Item no="1.1" />
        <Item no="1.2" />
        <Item no="1.3" />
        <Item no="1.4" />

        <Typography variant="h6" p="10px 0px">
          2. {t(2)}
        </Typography>
        <Item no="2.1" />
        <Item no="2.2" />
        <Item no="2.3" />
        <Item no="2.3.a" />
        <Item no="2.3.b" />
        <Item no="2.3.c" />
        <Item no="2.4" />
        <Item no="2.5" />
        <Typography variant="h6" p="10px 0px">
          3. {t(3)}
        </Typography>

        <Item no="3.1" />
        <Item no="3.1.a" />
        <Item no="3.1.b" />
        <Item no="3.1.c" />
        <Item no="3.2" />
        <Item no="3.3" />

        <Typography variant="h6" p="10px 0px">
          4. {t(4)}
        </Typography>

        <Item no="4.1" />
        <Item no="4.2" />
        <Item no="4.3" />
        <Typography variant="h6" p="10px 0px">
          5. {t(5)}
        </Typography>
        <Item no="5.1" />
        <Item no="5.2" />

        <Typography variant="h6" p="10px 0px">
          6. {t(6)}
        </Typography>
        <Item no="6.1" />
        <Item no="6.1.a" />
        <Item no="6.1.b" />
        <Item no="6.2" />
        <Item no="6.3" />
        <Item no="6.4" />
        <Item no="6.4.a" />
        <Item no="6.4.b" />
        <Item no="6.4.c" />
        <Item no="6.5" />

        <Typography variant="h6" p="10px 0px">
          7. {t(7)}
        </Typography>
        <Item no="7.1" />
        <Item no="7.2" />
        <Item no="7.3" />
        <Item no="7.4" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="black">
          {_t("Common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
