import { connect } from "react-redux";

import { useMyGifts } from "../../hooks";
import { openGiftModal } from "../../store/actions/modals";
import { useParams } from "react-router-dom";
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FaShoppingBag } from "react-icons/fa";
import { PhotoView } from "react-photo-view";
import { FaCheckCircle } from "react-icons/fa";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useState } from "react";

const PropositionGiftTable = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "MyGiftsTable" });
  const { listId } = useParams();

  const [giftApproveConfirmation, setGiftApproveConfirmation] = useState(null);

  const { data: myGifts, _approve, isLoading } = useMyGifts();
  const gifts = myGifts.filter(
    (gift) =>
      String(gift.list) === listId && gift.is_pending_proposition_approval
  );

  const approve = () =>
    _approve
      .mutateAsync(giftApproveConfirmation)
      .then(() => setGiftApproveConfirmation(false));

  if (isLoading) return null;
  if (!gifts.length) return <div>{t("noGifts")}</div>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("columns.giftName")}</TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.price")}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.store")}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.image")}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.approve")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {gifts.map((gift) => (
          <TableRow
            selected={gift.reserved}
            hover
            sx={{
              cursor: "pointer",
              backgroundColor: "#fff9c4",
              ":hover": { backgroundColor: "#fff59d !important" },
            }}
            key={gift.id}
            onClick={() => props.openGiftModal(gift)}
          >
            <TableCell>
              {gift.name}
              <br />
              <small>{gift.description}</small>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {gift.price_range}
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {!gift.buy_link ? (
                "-"
              ) : (
                <Button
                  variant="contained"
                  color="green"
                  size="small"
                  startIcon={<FaShoppingBag size={18} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(gift.buy_link, "_blank")
                  }}
                >
                  {t("shop")}
                </Button>
              )}
            </TableCell>
            <TableCell
              sx={{ textAlign: "center" }}
              onClick={(e) => e.stopPropagation()}
            >
              {!gift.image_link ? (
                "-"
              ) : (
                <PhotoView src={gift.image_link}>
                  <img
                    src={gift.image_link}
                    alt=""
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      cursor: "pointer",
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                  />
                </PhotoView>
              )}
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <Chip
                label={t("columns.approve")}
                avatar={<FaCheckCircle color="orange" />}
                onClick={(e) => {
                  e.stopPropagation();
                  setGiftApproveConfirmation(gift.id);
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <ConfirmationModal
        open={!!giftApproveConfirmation}
        onClose={() => setGiftApproveConfirmation(null)}
        title={<Trans i18nKey="MyGiftsTable.approveConfirmation" />}
        message={<Trans i18nKey="MyGiftsTable.approveConfirmationMessage" />}
        onConfirm={approve}
      />
    </Table>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { openGiftModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropositionGiftTable);
