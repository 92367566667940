import { connect } from "react-redux";

import { useMyGifts } from "../../hooks";
import { openGiftModal } from "../../store/actions/modals";
import { useParams } from "react-router-dom";
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FaShoppingBag } from "react-icons/fa";
import { PhotoView } from "react-photo-view";
import { FaCheckCircle, FaRegSmile } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const MyGiftsTable = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "MyGiftsTable" });
  const { listId } = useParams();
  const { data: myGifts, isLoading } = useMyGifts();
  const gifts = myGifts.filter(
    (gift) =>
      String(gift.list) === listId && !gift.is_pending_proposition_approval
  );

  if (isLoading) return null;
  if (!gifts.length) return <div>{t("noGifts")}</div>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("columns.giftName")}</TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.price")}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.store")}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.image")}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {t("columns.reserved")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {gifts.map((gift) => (
          <TableRow
            selected={gift.reserved}
            hover
            sx={{
              cursor: "pointer",
              "&.Mui-selected": { backgroundColor: "#e8f5e9" },
              "&.Mui-selected:hover": { backgroundColor: "#c8e6c9" },
            }}
            key={gift.id}
            onClick={() => props.openGiftModal(gift)}
          >
            <TableCell>
              {gift.name}
              <br />
              <small>{gift.description}</small>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {gift.price_range}
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {!gift.buy_link ? (
                "-"
              ) : (
                <Button
                  variant="contained"
                  color="green"
                  size="small"
                  startIcon={<FaShoppingBag size={18} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(gift.buy_link, "_blank");
                  }}
                >
                  {t("shop")}
                </Button>
              )}
            </TableCell>
            <TableCell
              sx={{ textAlign: "center" }}
              onClick={(e) => e.stopPropagation()}
            >
              {!gift.image_link ? (
                "-"
              ) : (
                <PhotoView src={gift.image_link}>
                  <img
                    src={gift.image_link}
                    alt=""
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      cursor: "pointer",
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                  />
                </PhotoView>
              )}
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {gift.reserved ? (
                <Chip
                  label={t("reserved")}
                  avatar={<FaCheckCircle color="green" />}
                />
              ) : (
                <Chip label={t("available")} avatar={<FaRegSmile />} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { openGiftModal };

export default connect(mapStateToProps, mapDispatchToProps)(MyGiftsTable);
