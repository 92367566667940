import { Box, Button, Dialog, IconButton } from "@mui/material";

import { useTranslation } from "react-i18next";
import PropositionGiftTable from "./PropositionGiftTable.js";
import {
  FaAngleDown,
  FaAngleRight,
  FaExclamationTriangle,
} from "react-icons/fa";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { useParams } from "react-router-dom";
import { useMyGifts } from "../hooks";
import MobileWrapper from "../components/MobileWrapper.js";
import PropositionGiftCards from "./PropositionGiftTable.js/PropositionGiftCards.js";
import { IoClose } from "react-icons/io5";

export default function PropositionGifts() {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const { listId } = useParams();
  const { data: myGifts } = useMyGifts();
  const gifts = myGifts.filter(
    (gift) =>
      String(gift.list) === listId && gift.is_pending_proposition_approval
  );

  const CollapseIcon = collapsed ? FaAngleRight : FaAngleDown;

  if (!gifts.length) return null;

  return (
    <>
      <Button
        fullWidth
        color="black"
        sx={{ textTransform: "none" }}
        startIcon={<FaExclamationTriangle />}
        onClick={() => setCollapsed((prevState) => !prevState)}
      >
        <Box position="absolute" left="10px">
          <CollapseIcon />
        </Box>
        {t("GiftList.giftsProposition", { count: gifts.length })}
      </Button>
      <MobileWrapper
        component={
          <Dialog
            open={!collapsed}
            onClose={() => setCollapsed(true)}
            fullScreen
          >
            <Box width="100%" textAlign="right">
              <IconButton sx={{ m: "10px" }} onClick={() => setCollapsed(true)}>
                <IoClose fontSize="24px" />
              </IconButton>
            </Box>
            <PropositionGiftCards />
          </Dialog>
        }
      >
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
          <PropositionGiftTable />
        </Collapse>
      </MobileWrapper>
    </>
  );
}
