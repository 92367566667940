import { connect } from "react-redux";

import { AppBar, Box, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import ProfileMenu from "./ProfileMenu";
import LoginButton from "../components/LoginButton";
import LanguageButton from "../components/LanguageButton";
import MobileWrapper from "../components/MobileWrapper";

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const Title = styled("div")`
  font-family: "FairProsper";
  font-size: 34px;
  padding-top: 5px;
  cursor: pointer;
  position: absolute;
  width: calc(100% - 64px);
  text-align: center;
`;

const AppToolbar = (props) => {
  const { fullWidth = true } = props;
  const navigate = useNavigate();
  return (
    <AppBar
      sx={{
        left: !fullWidth ? 300 : 0,
        width: !fullWidth ? "calc(100vw - 300px)" : "100%",
      }}
    >
      <StyledToolbar>
        <div />
        <Title onClick={() => navigate("/")}>Gifteeme</Title>
        <Box>
          <LanguageButton />
          {!props.user ? (
            <MobileWrapper>
              <LoginButton />
            </MobileWrapper>
          ) : (
            <ProfileMenu />
          )}
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AppToolbar);
