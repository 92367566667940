import { styled } from "@mui/material/styles";

import Login from "../modals/LoginModal/Login";

const InnerContainer = styled("div")`
  margin: auto;
  width: 500px;
`;

const LoginPage = (props) => {
  return (
    <InnerContainer>
      <Login />
    </InnerContainer>
  );
};

export default LoginPage;
