import axios from "axios";
import { API_URL, TOKEN_KEY } from "./conf.js";
import { toast } from "react-toastify";

export const AUTH_PATH = `${API_URL}/api/v1/auth/token/`;

const axiosClient = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const responseErrorHandler = (error) => {
  const requestPath = new URL(API_URL + error?.response.config.url).pathname;
  if (requestPath === AUTH_PATH) toast.error("Error 401: Authentication error");

  if (error?.response?.status === 404)
    toast.error("Obiekt nie istnieje.");

  if (error?.response?.status === 401) {
    const msg = JSON.stringify(error?.response?.data) || "Unknown Error";
    toast.error("Error 401: " + msg);
    localStorage.removeItem(TOKEN_KEY);
    return window.location.reload();
  }
};

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    responseErrorHandler(error);
    return Promise.reject(error);
  }
);

export default axiosClient;
